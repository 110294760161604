import React from "react";
import { Box, Button, Grid, TextField, Typography, Link } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "./background-animation.json";

interface MobilePageProps {
    firstName: string;
    setFirstName: (value: string) => void;
    lastName: string;
    setLastName: (value: string) => void;
    email: string;
    setEmail: (value: string) => void;
    password: string;
    setPassword: (value: string) => void;
    handleRegister: (event: React.FormEvent<HTMLFormElement>) => void;
}


const MobilePage: React.FC<MobilePageProps> = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    handleRegister
}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <div>
            <Box sx={{ position: "fixed", zIndex: -1 }}>
                <Lottie options={defaultOptions} height={'100vh'} width={'100vw'} sx={{ zIndex: -1 }} />
            </Box>

            <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        width: '90%',
                        textAlign: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleRegister}
                >
                    <Typography variant="h4" fontSize={60} gutterBottom>Opret Profil</Typography>
                    {/* <Typography variant="h4" fontSize={16} gutterBottom>Brug dine sociale medier til at oprette én profil</Typography>
                    <Box sx={{ m: 1, minWidth: "100%", display: 'flex', justifyContent: 'space-evenly' }}>
                        <SocialLogin isMobile={true} Icon={GoogleIcon} Social={"Google"} />
                        <SocialLogin isMobile={true} Icon={AppleIcon} Social={"Apple"} />
                        <SocialLogin isMobile={true} Icon={FacebookIcon} Social={"Facebook"} />
                    </Box>
                    <Divider sx={{ width: "100%" }}>ELLER</Divider> */}
                    {/* <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="FirstName"
                                name="FirstName"
                                label="Fornavn"
                                variant="outlined"
                                fullWidth
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                sx={{ backgroundColor: "white" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="LastName"
                                name="LastName"
                                label="Efternavn"
                                variant="outlined"
                                fullWidth
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                sx={{ backgroundColor: "white" }}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="e-mail"
                        type="email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ backgroundColor: "white" }}
                    />
                    <TextField
                        required
                        id="password"
                        name="password"
                        label="Ny adgangskode"
                        type="password"
                        variant="outlined"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ backgroundColor: "white" }}
                    />
                    <Button variant="contained" color="primary" size="small" type="submit" fullWidth sx={{ boxShadow: "0px 4px 2px 2px black" }}>
                        Opret profil
                    </Button> */}
                    <Typography variant="body1" color="text.secondary" sx={{ marginTop: 2 }}>Opretning af profiler er midlertidigt deaktiveret...</Typography>
                    {/* <Link href="/login">Jeg har allerrede en profil</Link> */}
                </Box>
            </Box>
        </div>
    );
};

export default MobilePage;
