import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { updateLocationAdmin } from "api/adminEvents";
import { useSnackbar } from "hooks/useSnackBar";

interface LocationFormProps {
  locationId: string;
  initialData: {
    venue: string;
    address: string;
    city: string;
    postal_code: string;
    country: string;
    map_link: string;
  };
}

const LocationForm: React.FC<LocationFormProps> = ({ locationId, initialData }) => {
  const defaultData = {
    venue: "",
    address: "",
    city: "",
    postal_code: "",
    country: "",
    map_link: "",
  };
  const [formData, setFormData] = useState(initialData || defaultData) as any;
  const { showSnackbar } = useSnackbar();

  const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev : any) => ({ ...prev, [field]: event.target.value }));
  };

  const handleBlur = (field: string) => async () => {
    try {
      await updateLocationAdmin(locationId, field, formData[field]);
      showSnackbar("Opdateret!", "success");
    } catch {
      showSnackbar("Fejl ved opdatering!", "error");
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Lokation Information</Typography>

      <TextField
        label="Venue"
        value={formData?.venue || ""}
        onChange={handleChange("venue")}
        onBlur={handleBlur("venue")}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Adresse"
        value={formData?.address || ""}
        onChange={handleChange("address")}
        onBlur={handleBlur("address")}
        fullWidth
        margin="normal"
      />

      <TextField
        label="By"
        value={formData?.city || ""}
        onChange={handleChange("city")}
        onBlur={handleBlur("city")}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Postnummer"
        value={formData?.postal_code || ""}
        onChange={handleChange("postal_code")}
        onBlur={handleBlur("postal_code")}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Land"
        value={formData?.country || ""}
        onChange={handleChange("country")}
        onBlur={handleBlur("country")}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Kortlink"
        value={formData?.map_link || ""}
        onChange={handleChange("map_link")}
        onBlur={handleBlur("map_link")}
        fullWidth
        margin="normal"
      />
    </Box>
  );
};

export default LocationForm;
