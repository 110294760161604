import React, { useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Box,
  Skeleton,
  IconButton,
  Modal,
  Divider,
  Grid,
} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { EventParticipant } from "types/interfaces";
import { calculateAge } from "utils/calculateAge";
import { useEventParticipantsAdmin } from "hooks/useEventParticipantsAdmin";
import { updateBibNumber } from "api/events";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

interface ParticipantAdminProps {
  eventId: string;
}

interface EditEventParticipant extends EventParticipant {
  bibEditing?: boolean;
  onBibEdit?: () => void;
  bibUpdating?: boolean;
  onBibChange?: (newBib: string) => void;
  onBibBlur?: () => void;
}

const ParticipantsAdmin: React.FC<ParticipantAdminProps> = ({ eventId }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { eventParticipants, eventLoading, error } =
    useEventParticipantsAdmin(eventId);
  const [selectedParticipant, setSelectedParticipant] =
    useState<EventParticipant | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (participant: EventParticipant) => {
    setSelectedParticipant(participant);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedParticipant(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredParticipants = Array.isArray(eventParticipants)
    ? eventParticipants.filter((participant: EventParticipant) =>
        Object.values(participant).some((value) =>
          Array.isArray(value)
            ? value.some((chip) =>
                chip?.toLowerCase().includes(searchTerm.toLowerCase())
              )
            : value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      ) || []
    : [];

  const enhanceParticipants = (participants: EditEventParticipant[]) => {
    return participants.map((participant) => ({
      ...participant,
      bibEditing: false,
    }));
  };

  const handleBibEdit = (id: string) => {
    setEnhancedParticipants((prev) =>
      prev.map((p) => (p.id === id ? { ...p, bibEditing: true } : p))
    );
  };

  const handleBibChange = (id: string, newBib: string) => {
    setEnhancedParticipants((prev) =>
      prev.map((p) => (p.id === id ? { ...p, bib: newBib } : p))
    );
  };

  const handleBibBlur = async (id: string) => {
    const participant = enhancedParticipants.find((p) => p.id === id);
    if (!participant) return;

    setEnhancedParticipants((prev) =>
      prev.map((p) => (p.id === id ? { ...p, bibUpdating: true } : p))
    );

    try {
      const updated = await updateBibNumber(id, participant.bib || "");

      setEnhancedParticipants((prev) =>
        prev.map((p) =>
          p.id === id
            ? {
                ...p,
                ...updated,
                bibEditing: false,
                bibUpdating: false,
              }
            : p
        )
      );
    } catch (error) {
      console.error("Failed to update bib number:", error);

      setEnhancedParticipants((prev) =>
        prev.map((p) =>
          p.id === id ? { ...p, bibEditing: false, bibUpdating: false } : p
        )
      );
    }
  };

  const [enhancedParticipants, setEnhancedParticipants] = useState(
    enhanceParticipants(eventParticipants || [])
  );

  React.useEffect(() => {
    if (eventParticipants) {
      setEnhancedParticipants(enhanceParticipants(eventParticipants));
    }
  }, [eventParticipants]);

  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          marginTop: 20,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Typography variant="h1" fontSize={60} color="error">
          {error}
        </Typography>
        <SentimentVeryDissatisfiedIcon
          color="error"
          sx={{
            fontSize: 200,
            textAlign: "center",
            mt: 2,
            mb: 2,
            color: "error",
            ml: "auto",
            mr: "auto",
          }}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "#000",
          width: "100%",
          top: 0,
          left: 0,
          padding: 1.5,
        }}
      >
        <Typography variant="h4" color="#fff">
          Deltagere
        </Typography>
      </Box>
      <TextField
        label="Søg efter deltager..."
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        disabled={eventLoading}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>Navn</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Køn</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Alder</TableCell>
              {/* <TableCell sx={{ fontWeight: 700 }}>Nationalitet</TableCell> */}
              <TableCell sx={{ fontWeight: 700 }}>Billet</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Bib nr.</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Info</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventLoading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton width="80%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton width="40%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton width="30%" />
                    </TableCell>
                    {/* <TableCell><Skeleton width="50%" /></TableCell> */}
                    <TableCell>
                      <Skeleton width="60%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton width="30%" />
                    </TableCell>
                  </TableRow>
                ))
              : filteredParticipants.map((participant: EventParticipant) => (
                  <TableRow key={participant.id}>
                    <TableCell>
                      {participant.first_name} {participant.last_name}
                    </TableCell>
                    <TableCell>{participant.gender}</TableCell>
                    <TableCell>{calculateAge(participant.birthday)}</TableCell>
                    {/* <TableCell>
                      <CountryFlag nationality={participant.nationality} />
                      {participant.nationality}
                    </TableCell> */}
                    <TableCell>{participant.ticket_type?.name}</TableCell>
                    <TableCell
                      sx={{ position: "relative", overflow: "hidden" }}
                    >
                      {(() => {
                        const enhanced = enhancedParticipants.find(
                          (p) => p.id === participant.id
                        );
                        if (!enhanced) return null;

                        if (enhanced.bibUpdating) {
                          return (
                            <Skeleton
                              width="60px"
                              sx={{ position: "absolute", top: 10 }}
                            />
                          );
                        }

                        const commonStyles = {
                          width: "100px",
                          fontSize: "inherit",
                          padding: "8px",
                          boxSizing: "border-box",
                          typography: "body2",
                          p: 0,
                        };

                        return enhanced.bibEditing ? (
                          <TextField
                            value={enhanced.bib || ""}
                            onChange={(e) =>
                              handleBibChange(participant.id, e.target.value)
                            }
                            onBlur={() => handleBibBlur(participant.id)}
                            size="small"
                            autoFocus
                            sx={{
                              ...commonStyles,
                              display: "inline-block",
                              position: "absolute",
                              top: 5,
                              left: 0,
                              fontFamily: "Freeman",
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              ...commonStyles,
                              ":hover": { cursor: "pointer" },
                              display: "inline-block",
                              position: "absolute",
                              top: 10,
                            }}
                            onClick={() => handleBibEdit(participant.id)}
                          >
                            {enhanced.bib || "..."}
                          </Box>
                        );
                      })()}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleOpenModal(participant)}
                        size="small"
                      >
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            minWidth: 400,
          }}
        >
          {selectedParticipant && (
            <>
              <Typography variant="h6" gutterBottom>
                {selectedParticipant.first_name} {selectedParticipant.last_name}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ width: "100%" }}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  {/* Personlig info */}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      gutterBottom
                    >
                      👤 Personlig information
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Navn:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.first_name}{" "}
                      {selectedParticipant.last_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Køn:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.gender}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Fødselsdato:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.birthday}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Alder:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {calculateAge(selectedParticipant.birthday)}
                    </Typography>
                  </Grid>

                  {/* Adresse */}
                  <Grid item xs={12} mt={2}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      gutterBottom
                    >
                      🏠 Adresse
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Adresse:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Postnummer:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.post_code}
                    </Typography>
                  </Grid>

                  {/* Kontakt */}
                  <Grid item xs={12} mt={2}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      gutterBottom
                    >
                      📬 Kontakt
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Email:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Telefon:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.phone_number}
                    </Typography>
                  </Grid>

                  {/* Eventoplysninger */}
                  <Grid item xs={12} mt={2}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      gutterBottom
                    >
                      🎟️ Eventoplysninger
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Billet:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.ticket_type?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Bib:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.bib}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong>Registreret:</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {selectedParticipant.registration_date}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default ParticipantsAdmin;
