import React from 'react';
import { Grid, TextField } from '@mui/material';

interface OrderDetailsFormProps {
    firstName: string;
    setFirstName: (value: string) => void;
    lastName: string;
    setLastName: (value: string) => void;
    email: string;
    setEmail: (value: string) => void;
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
    address: string;
    setAddress: (value: string) => void;
    city: string;
    setCity: (value: string) => void;
    postalCode: string;
    setPostalCode: (value: string) => void;
    country: string;
    setCountry: (value: string) => void;
}

const OrderDetailsForm: React.FC<OrderDetailsFormProps> = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phoneNumber,
    setPhoneNumber,
    address,
    setAddress,
    city,
    setCity,
    postalCode,
    setPostalCode,
    country,
    setCountry
}) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Fornavn"
                    fullWidth
                    value={firstName}
                    onChange={(e: any) => setFirstName(e.target.value)}
                    variant="outlined"
                    autoComplete="given-name"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Efternavn"
                    fullWidth
                    value={lastName}
                    onChange={(e: any) => setLastName(e.target.value)}
                    variant="outlined"
                    autoComplete="family-name"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    variant="outlined"
                    autoComplete="email"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Telefon nr."
                    fullWidth
                    value={phoneNumber}
                    onChange={(e: any) => setPhoneNumber(e.target.value)}
                    variant="outlined"
                    autoComplete="tel"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Addresse"
                    fullWidth
                    value={address}
                    onChange={(e: any) => setAddress(e.target.value)}
                    variant="outlined"
                    autoComplete="street-address"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="By"
                    fullWidth
                    value={city}
                    onChange={(e: any) => setCity(e.target.value)}
                    variant="outlined"
                    autoComplete="address-level2"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Postnummer"
                    fullWidth
                    value={postalCode}
                    onChange={(e: any) => setPostalCode(e.target.value)}
                    variant="outlined"
                    autoComplete="postal-code"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Land"
                    fullWidth
                    value={country}
                    onChange={(e: any) => setCountry(e.target.value)}
                    variant="outlined"
                    autoComplete="country"
                />
            </Grid>
        </Grid>
    );
};

export default OrderDetailsForm;