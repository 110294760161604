import React, { useState } from "react";
import { Box, TextField, Typography, Button, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { registerContestant } from "api/events";
import { useSnackbar } from "hooks/useSnackBar";
import MassRegisterModal from "./MassRegisterModal";

interface TicketType {
  id: string;
  name: string;
}

interface ContestantRegistrationFormProps {
  eventId: string;
  ticketTypes: TicketType[];
}

const ContestantRegistrationForm: React.FC<ContestantRegistrationFormProps> = ({
  eventId,
  ticketTypes,
}) => {
  const { showSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const [formData, setFormData] = useState({
    ticket_type_id: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    gender: "",
    birthday: "",
    address: "",
    post_code: "",
    nationality: "",
    pi_id: "",
    bib: "",
  });

  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({ ...prev, [field]: event.target.value }));
    };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!formData.ticket_type_id) {
      showSnackbar("Vælg venligst en billet-type!", "warning");
      return;
    }
    try {
      await registerContestant(eventId, formData);
      showSnackbar("Tilmelding gennemført!", "success");
      setFormData({
        ticket_type_id: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        gender: "",
        birthday: "",
        address: "",
        post_code: "",
        nationality: "",
        pi_id: "",
        bib: "",
      });
    } catch {
      showSnackbar("Fejl ved tilmelding!", "error");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        position: "relative",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Tilmelding til Event
      </Typography>

      <TextField
        label="Vælg billet-type"
        select
        value={formData.ticket_type_id}
        onChange={handleChange("ticket_type_id")}
        required
        fullWidth
      >
        {ticketTypes.map((ticket) => (
          <MenuItem key={ticket.id} value={ticket.id}>
            {ticket.name}
          </MenuItem>
        ))}
      </TextField>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid size={6}>
          <TextField
            label="Fornavn"
            value={formData.first_name}
            onChange={handleChange("first_name")}
            required
            fullWidth
          />
        </Grid>
        <Grid size={6}>
          <TextField
            label="Efternavn"
            value={formData.last_name}
            onChange={handleChange("last_name")}
            required
            fullWidth
          />
        </Grid>
        <Grid size={6}>
          <TextField
            label="Telefon"
            value={formData.phone_number}
            onChange={handleChange("phone_number")}
            required
            fullWidth
          />
        </Grid>
        <Grid size={6}>
          <TextField
            label="Email"
            type="email"
            value={formData.email}
            onChange={handleChange("email")}
            required
            fullWidth
          />
        </Grid>

        <Grid size={6}>
          <TextField
            label="Køn"
            select
            value={formData.gender}
            onChange={handleChange("gender")}
            required
            fullWidth
          >
            <MenuItem value="male">Mand</MenuItem>
            <MenuItem value="female">Kvinde</MenuItem>
            <MenuItem value="other">Andet</MenuItem>
          </TextField>
        </Grid>
        <Grid size={6}>
          <TextField
            label="Fødselsdag"
            type="date"
            value={formData.birthday}
            onChange={handleChange("birthday")}
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid size={6}>
          <TextField
            label="Adresse"
            value={formData.address}
            onChange={handleChange("address")}
            fullWidth
          />
        </Grid>
        <Grid size={6}>
          <TextField
            label="Postnummer"
            value={formData.post_code}
            onChange={handleChange("post_code")}
            fullWidth
          />
        </Grid>
        <Grid size={6}>
          <TextField
            label="Nationalitet"
            value={formData.nationality}
            onChange={handleChange("nationality")}
            fullWidth
          />
        </Grid>
        <Grid size={6}>
          <TextField
            label="Bib nr."
            value={formData.bib}
            onChange={handleChange("bib")}
            fullWidth
          />
        </Grid>
      </Grid>

      <Button type="submit" variant="contained" color="primary">
        Tilmeld
      </Button>
      <MassRegisterModal
        open={modalOpen}
        handleClose={handleCloseModal}
        eventId={eventId}
        ticketTypes={ticketTypes}
      />
      <Button
        sx={{
          position: "absolute",
          right: -20,
          top: -20,
          transform: "scale(0.75)",
        }}
        onClick={handleOpenModal}
      >
        Masseregistrer
      </Button>
    </Box>
  );
};

export default ContestantRegistrationForm;
