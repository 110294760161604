import React, { useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TextField,
  Modal,
  Box,
  Divider,
} from "@mui/material";
import EditForm from "components/admin/users/EditForm";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useUsersAdmin } from "hooks/useUsersAdmin";

interface UserDetails {
  first_name: string | null;
  last_name: string | null;
  birthday: string | null;
  gender: string | null;
  nationality: string | null;
  phone_number: string | null;
  address: string | null;
  city: string | null;
  postal_code: string | null;
  affiliation: string | null;
  image: string | null;
}

interface User {
  id: string;
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  is_verified: boolean;
  details: UserDetails;
}

const Users: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortBy, setSortBy] = useState<keyof User>("email");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { users, setUsers, refetchUsers } = useUsersAdmin();



  const handleSort = (column: keyof User) => {
    const order = sortBy === column && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(order);
    setUsers((prevUsers) =>
      [...prevUsers].sort((a, b) => {
        const aValue = a[column] as string;
        const bValue = b[column] as string;
        if (aValue < bValue) return order === "asc" ? -1 : 1;
        if (aValue > bValue) return order === "asc" ? 1 : -1;
        return 0;
      })
    );
  };

  const filteredUsers = users.filter(
    (user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.details.first_name &&
        user.details.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.details.last_name &&
        user.details.last_name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleUserClick = (user: User) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Brugere
      </Typography>
      <TextField
        label="Søg efter bruger..."
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "email"}
                  direction={sortBy === "email" ? sortOrder : "asc"}
                  onClick={() => handleSort("email")}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>Fornavn</TableCell>
              <TableCell>Efternavn</TableCell>
              <TableCell>Aktiv</TableCell>
              <TableCell>Superbruger</TableCell>
              <TableCell>Verificeret</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id} onClick={() => handleUserClick(user)} sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#f5f5f5" } }}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.details?.first_name || "N/A"}</TableCell>
                <TableCell>{user.details?.last_name || "N/A"}</TableCell>
                <TableCell>{user.is_active ? <CheckIcon sx={{color: "green"}} /> : <CloseIcon sx={{color: "red"}} />}</TableCell>
                <TableCell>{user.is_superuser ? <CheckIcon sx={{color: "green"}} /> : <CloseIcon sx={{color: "red"}} />}</TableCell>
                <TableCell>{user.is_verified ? <CheckIcon sx={{color: "green"}} /> : <CloseIcon sx={{color: "red"}} />}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            minWidth: 400,
            width: "50%",
            maxWidth: 1000,
            maxHeight: "90%",
            overflowY: "auto",
          }}
        >
          {selectedUser && (
            <>
            
              <Typography sx={{ fontWeight: "bold" }} variant="h6" gutterBottom>
                {selectedUser.details.first_name || "N/A"} {selectedUser.details.last_name || "N/A"}
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                {selectedUser.email}
              </Typography>
              <Divider sx={{mt:1, mb:1}}/> 
              <EditForm selectedUser={selectedUser} closeModal={closeModal} refetchUsers={refetchUsers}/>
              
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Users;
