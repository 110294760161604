import { Event } from "types/interfaces";
import { getEvent } from "api/events";
import { useEffect, useState, useCallback } from "react";

export const useEvent = (eventId: string) => {
    const [eventInfo, setEvent] = useState<Event | null>(null);
    const [eventLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchEvent = useCallback(async () => {
        setLoading(true);
        try {
            const data = await getEvent(eventId);
            setEvent(data);
            setError(null); 
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [eventId]);

    useEffect(() => {
        if (eventId) fetchEvent();
    }, [eventId, fetchEvent]);

    return { eventInfo, eventLoading, error, setEvent, refetchEvent: fetchEvent };
};
