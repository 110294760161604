import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NavigationCardGrid from 'components/admin/NavigationCardGrid';

const EventRegistration: React.FC = () => {
    const linkItems = [
        { text: 'Opret nyt event', description: "Opret et helt nyt event..", icon: <AddIcon />, path: '/admin/registrations/new' },
        { text: 'Manage Event Registrations', description: "View and manage event registrations.", icon: <ManageAccountsIcon />, path: '/admin/registrations/manage' },
    ];

    return (
        <NavigationCardGrid
            title="Event Registration"
            description="Welcome to the admin dashboard!"
            linkItems={linkItems}
        />
    );
};

export default EventRegistration;
