import React, { ReactNode, useState } from "react";
import {
  AppBar,
  Box,
  Breadcrumbs,
  Collapse,
  CssBaseline,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import EventIcon from "@mui/icons-material/Event";
import PeopleIcon from "@mui/icons-material/People";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';

const drawerWidth = 200;
const miniDrawerWidth = 56;

const breadcrumbNameMap: { [key: string]: string } = {
  "/admin": "Admin",
  "/admin/dashboard": "Dashboard",
  "/admin/users": "Users",
  "/admin/registrations": "Registrations",
  "/admin/registrations/new": "New Registration",
  "/admin/registrations/manage": "Manage Registrations",
  "/admin/readers": "Readers",
  "/admin/registrations/manage/event": "Event Details",
};


interface AdminLayoutProps {
  children: ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [drawerExpanded, setDrawerExpanded] = useState(false);
  const [eventMenuOpen, setEventMenuOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/admin/dashboard" },
    {
      text: "Events",
      icon: <EventIcon />,
      path: "/admin/registrations",
      subMenu: [
        {
          text: "New Registration",
          icon: <AddIcon />,
          path: "/admin/registrations/new",
        },
        {
          text: "Manage Registrations",
          icon: <ManageAccountsIcon />,
          path: "/admin/registrations/manage",
        },
      ],
    },
    { text: "Users", icon: <PeopleIcon />, path: "/admin/users" },
    { text: "Readers", icon: <FingerprintOutlinedIcon />, path: "/admin/readers" },
  ];

  const toggleDrawer = () => setDrawerExpanded(!drawerExpanded);
  const handleEventMenuClick = (path: string) => {
    if (!drawerExpanded) navigate(path);
    else setEventMenuOpen(!eventMenuOpen);
  };
  const handleNavigate = (path: string) => navigate(path);

  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#fff",
          borderBottom: "solid 1px black",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            src="/logo/transparent/black.png"
            onClick={() => (window.location.href = "/")}
            sx={{
              ml: 2,
              height: isMobile ? "32px" : "50px",
              width: isMobile ? "auto" : "10%",
              objectFit: "contain",
              cursor: "pointer",
              transition: "transform 0.2s ease-in-out",
              ":hover": { transform: "scale(1.05)" },
            }}
          />
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerExpanded ? drawerWidth : miniDrawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerExpanded ? drawerWidth : miniDrawerWidth,
            boxSizing: "border-box",
            transition: "width 0.3s",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {menuItems.map((item) => (
              <React.Fragment key={item.text}>
                <ListItemButton
                  onClick={() =>
                    item.subMenu
                      ? handleEventMenuClick(item.path)
                      : handleNavigate(item.path)
                  }
                  selected={
                    location.pathname === item.path ||
                    item.subMenu?.some(
                      (subItem) => location.pathname === subItem.path
                    )
                  }
                  sx={{
                    height: "40px",
                    "&.Mui-selected": {
                      backgroundColor: "#f4f4f4",
                      color: "#1976d2",
                      borderRight: "solid #fe8100 2px",
                    },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: "30px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
                        {item.text}
                      </Typography>
                    }
                    sx={{ display: drawerExpanded ? "block" : "none" }}
                  />
                  {item.subMenu &&
                    drawerExpanded &&
                    (eventMenuOpen ? (
                      <ExpandLessIcon sx={{ color: "black" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ color: "black" }} />
                    ))}
                </ListItemButton>

                {item.subMenu && (
                  <Collapse
                    in={eventMenuOpen && drawerExpanded}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.subMenu.map((subItem) => (
                        <ListItemButton
                          key={subItem.text}
                          sx={{ pl: 4 }}
                          onClick={() => handleNavigate(subItem.path)}
                          selected={location.pathname === subItem.path}
                        >
                          <ListItemIcon sx={{ minWidth: "25px" }}>
                            {subItem.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "0.7rem" }}
                              >
                                {subItem.text}
                              </Typography>
                            }
                            sx={{ display: drawerExpanded ? "block" : "none" }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          marginLeft: drawerExpanded
            ? `${drawerWidth / 4}px`
            : `${miniDrawerWidth}px`,
          transition: "margin-left 0.3s",
        }}
      >
        <Toolbar />

        {/* MUI Breadcrumbs */}
        <Box sx={{ mb: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            {pathnames.map((value, index) => {
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;
              return isLast ? (
                <Typography key={to} color="text.primary">
                  {breadcrumbNameMap[to] || decodeURIComponent(value)}
                </Typography>
              ) : (
                <Link
                  key={to}
                  underline="hover"
                  color="inherit"
                  onClick={() => navigate(to)}
                  sx={{ cursor: "pointer" }}
                >
                  {breadcrumbNameMap[to] || decodeURIComponent(value)}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Box>

        {children}
      </Box>
    </Box>
  );
};

export default AdminLayout;
