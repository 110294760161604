import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Container,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { HandleLogout } from "api/auth";
import useAuth from "hooks/useAuth";

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { username, isAuthenticated, isSuperuser, loading } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const stringToColor = (string: string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  };

  const stringAvatar = (name: string) => ({
    sx: { bgcolor: stringToColor(name) },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  });

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /* const navLinks = [
    { text: "Home", href: "#home" },
    { text: "About", href: "#about" },
    { text: "Services", href: "#services" },
  ]; */

  const drawerList = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => setDrawerOpen(false)}
    >
      {/* <List>
        {navLinks.map(({ text, href }) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component="a" href={href}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      <Divider />
      {loading ? null : isAuthenticated ? (
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => (window.location.href = "/profile")}>
              <Avatar
                {...stringAvatar(username)}
                sx={{ fontSize: "12px", mr: 1 }}
              />
              {username}
            </ListItemButton>
          </ListItem>
          {isSuperuser && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => (window.location.href = "/admin")}>
                <AdminPanelSettingsIcon fontSize="small" sx={{ mr: 1 }} />
                Admin Panel
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton onClick={HandleLogout}>
              <Logout fontSize="small" sx={{ mr: 1 }} />
              Log ud
            </ListItemButton>
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => (window.location.href = "/login")}>
              <ListItemText primary="Login" />
            </ListItemButton>
          </ListItem>
        </List>
      )}
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <AppBar position="fixed" sx={{ backgroundColor: "#000" }}>
        <Box sx={{ height: isMobile ? "16px" : "20px", backgroundColor: "red", color: "whitesmoke", textAlign: "center" }} >HJEMMESIDEN ER I BETA VERSION</Box>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            minHeight: isMobile ? "56px" : "80px",
            px: 2,
          }}
        >
          <Box
            component="img"
            src="/logo/transparent/white.png"
            onClick={() => (window.location.href = "/")}
            sx={{
              height: isMobile ? "32px" : "50px",
              width: isMobile ? "auto" : "10%",
              objectFit: "contain",
              cursor: "pointer",
              transition: "transform 0.2s ease-in-out",
              ":hover": { transform: "scale(1.05)" },
            }}
          />

          {isMobile ? (
            <>
              <IconButton onClick={() => setDrawerOpen(true)} edge="end" color="inherit">
                <MenuIcon sx={{color:"white"}}/>
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
              >
                {drawerList}
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {/* {navLinks.map(({ text, href }) => (
                <Button key={text} href={href} variant="outlined" sx={{ color: "#fff", border: "none" }}>
                  {text}
                </Button>
              ))} */}

              {loading ? null : isAuthenticated ? (
                <>
                  <IconButton
                    onClick={handleMenuClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={openMenu ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                  >
                    <Avatar {...stringAvatar(username)} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openMenu}
                    onClose={handleMenuClose}
                    onClick={handleMenuClose}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 16,
                            width: 10,
                            height: 10,
                            bgcolor: "#000",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleMenuClose();
                        window.location.href = "/profile";
                      }}
                    >
                      <Avatar {...stringAvatar(username)} /> {username}
                    </MenuItem>
                    <Divider />
                    {isSuperuser && (
                      <MenuItem
                        onClick={() => {
                          handleMenuClose();
                          window.location.href = "/admin";
                        }}
                      >
                        <AdminPanelSettingsIcon fontSize="small" sx={{ mr: 1 }} />
                        Admin Panel
                      </MenuItem>
                    )}
                    {/* <MenuItem onClick={handleMenuClose}>
                      <Settings fontSize="small" sx={{ mr: 1 }} />
                      Indstillinger
                    </MenuItem> */}
                    <MenuItem onClick={HandleLogout}>
                      <Logout fontSize="small" sx={{ mr: 1 }} />
                      Log ud
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Button href="/login" variant="outlined" sx={{ color: "#fff", border: "none" }}>
                  Login
                </Button>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Container>
  );
};

export default Header;
