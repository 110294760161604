import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { resetPassword } from 'api/auth';

const ResetPasswordPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    useEffect(() => {
        const isValidLength = password.length >= 8;
        const doPasswordsMatch = password === confirmPassword;
        setPasswordsMatch(isValidLength && doPasswordsMatch);
    }, [password, confirmPassword]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!passwordsMatch) {
            setError('Passwords matcher ikke');
            return;
        }

        try {
            await resetPassword(token, password);
            window.location.href = '/login';
        } catch (e: any) {
            if (e.response.data.detail === 'RESET_PASSWORD_BAD_TOKEN') {
                setError('Ugyldig token: Venligst anmod om et nyt link, hvis du vil nulstille din adgangskode.');
            } else {
                setError('Noget gik galt. Prøv igen senere, eller kontakt support.');
            }
        }
    };


    return (
        <Container maxWidth="sm">
            <Box mt={14}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Reset adganskode
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Ny adganskode"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <TextField
                        label="Bekræft adganskode"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    {!passwordsMatch && password.length >= 8 && (
                        <Typography color="red" variant="body1">
                            Passwords matcher ikke
                        </Typography>
                    )}
                    {password.length > 0 && password.length < 8 && (
                        <Typography color="red" variant="body1">
                            Passwordet skal være mindst 8 tegn langt
                        </Typography>
                    )}
                    {error && (
                        <Typography color="red" variant="body1">
                            {error}
                        </Typography>
                    )}
                    <Box mt={2}>
                        <Button type="submit" variant="contained" color="primary" fullWidth disabled={!passwordsMatch}>
                            Reset adganskode
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
};

export default ResetPasswordPage;