import React from "react";
import {
  Box,
  Container,
} from "@mui/material";
import Lottie from 'react-lottie';
import animationData from './page-not-found-animation.json';

const NotFoundPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Container>
      <Box
        sx={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          marginTop: 20,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Lottie 
          options={defaultOptions}
          height={400}
          width={400}
        />
      </Box>
    </Container>
  );
};

export default NotFoundPage;
