import { EventParticipant } from "types/interfaces";
import { getEventParticipantsAdmin } from "api/events";
import { useEffect, useState } from "react";

export const useEventParticipantsAdmin = (eventId: string | undefined) => {
    const [eventParticipants, setEventParticipants] = useState<EventParticipant[] | null>(null);
    const [eventLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const data = await getEventParticipantsAdmin(eventId);
                setEventParticipants(data);
            } catch (err: any) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEvent();
    }, [eventId]);

    return { eventParticipants, eventLoading, error };
};