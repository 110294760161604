import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  useMediaQuery,
  Skeleton,
  IconButton,
  TextField,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { useEventScoreboard } from "hooks/useEventScoreboard";
import { calculateAge } from "utils/calculateAge";
import { calculateTotalTime } from "utils/scoreBoardFunctions";
import { EventParticipant } from "types/interfaces";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

interface EventResultsProps {
  eventParticipants: EventParticipant[] | null;
  eventParticipantLoading: boolean;
  participantError: string | null;
}

const Results: React.FC<EventResultsProps> = ({
  eventParticipants,
  eventParticipantLoading,
  participantError,
}) => {
  const { eventId } = useParams<{ eventId: string }>();
  const { eventScoreboard, scoreboardLoading, error, refetch } =
    useEventScoreboard(eventId);

  const isMobile = useMediaQuery("(max-width:600px)");
  const [search, setSearch] = useState("");

  const allRankedParticipants = Array.isArray(eventParticipants)
    ? eventParticipants
        .map((participant, index) => {
          const scoreboardEntry = eventScoreboard?.find(
            (entry: any) => entry.registration_id === participant.id
          );
          const totalTime = scoreboardEntry
            ? calculateTotalTime(
                scoreboardEntry.start_time,
                scoreboardEntry.end_time
              )
            : null;

          return {
            participant,
            totalTime,
            originalIndex: index,
          };
        })
        .sort((a, b) => {
          if (a.totalTime === null && b.totalTime === null) return 0;
          if (a.totalTime === null) return 1;
          if (b.totalTime === null) return -1;
          return a.totalTime.localeCompare(b.totalTime);
        })
        .map((item, index) => ({
          ...item,
          position: index + 1,
        }))
    : [];

  const filteredParticipants = allRankedParticipants.filter(({ participant }) =>
    `${participant.first_name} ${participant.last_name}`
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  return (
    <div>
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "#000",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          top: 0,
          left: 0,
          padding: 1,
          pl: 1.5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h4" color="#fff">
            Resultater
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.8 }}>
            <PeopleOutlineOutlinedIcon sx={{ fontSize: isMobile ? 16 : 16, color: "#ffffffa0" }} />
            <Typography
              variant="h6"
              fontSize={isMobile ? 12 : 12}
              mt={0.5}
              color="#ffffffa0"
            >
              Antal: {eventParticipants?.length}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={refetch}>
          <ReplayIcon
            sx={{
              fontSize: 18,
              color: "white",
              ":hover": { color: "#fe8100" },
            }}
          />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          backgroundColor: "#fafafa",
          justifyContent: isMobile ? "center" : "flex-start",
          width: "100%",
        }}
      >
        {(participantError || error) && (
          <Box sx={{ width: "100%" }}>
            <Typography
              variant="h4"
              color="error"
              sx={{ fontSize: isMobile ? 12 : 20, width: "100%", mb: 2 }}
            >
              {participantError || error}
            </Typography>
          </Box>
        )}
        <TextField
          label="Søg deltagere"
          variant="outlined"
          size="small"
          value={search}
          sx={{ width: isMobile ? "100%" : "100%" }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>

      <TableContainer component={Paper}>
        {isMobile ? (
          <Table>
            <TableBody>
              {scoreboardLoading || eventParticipantLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          p: 1,
                          borderBottom: "1px solid #ddd",
                          position: "relative",
                          height: "120px",
                        }}
                      >
                        <Skeleton
                          variant="text"
                          width="50%"
                          sx={{
                            position: "absolute",
                            top: 16,
                            left: 10,
                            fontSize: 20,
                          }}
                        />
                        <Skeleton
                          variant="text"
                          width="40%"
                          sx={{ position: "absolute", top: 16, right: 10 }}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 10,
                            display: "flex",
                            flexDirection: "row",
                            gap: 10,
                            justifyContent: "center",
                            width: "96%",
                          }}
                        >
                          <Skeleton variant="text" width="30%" />
                          <Skeleton
                            variant="text"
                            width="30%"
                            sx={{ fontSize: 26 }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                : filteredParticipants.map(({ participant, position }) => {
                    const scoreboardEntry = eventScoreboard?.find(
                      (entry: any) => entry.registration_id === participant.id
                    );

                    return (
                      <TableRow key={participant.id}>
                        <TableCell
                          sx={{
                            p: 1,
                            borderBottom: "1px solid #ddd",
                            position: "relative",
                            height: "120px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              position: "absolute",
                              fontSize: isMobile ? 14 : 20,
                              top: 12,
                              left: 10,
                              width: 180,
                            }}
                          >
                            <strong>{position}.</strong>{" "}
                            {participant.first_name} {participant.last_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ position: "absolute", top: 16, right: 10 }}
                          >
                            {(participant.gender?.toLowerCase() ?? "") ===
                              "female" ||
                            (participant.gender?.toLowerCase() ?? "") ===
                              "kvinde" ? (
                              <FemaleIcon
                                sx={{ verticalAlign: "middle", fontSize: 16 }}
                              />
                            ) : (participant.gender?.toLowerCase() ?? "") ===
                                "male" ||
                              (participant.gender?.toLowerCase() ?? "") ===
                                "mand" ? (
                              <MaleIcon
                                sx={{ verticalAlign: "middle", fontSize: 16 }}
                              />
                            ) : (
                              participant.gender ?? "Andet"
                            )}
                            | <strong>Alder:</strong>{" "}
                            {calculateAge(participant.birthday)}
                          </Typography>
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 10,
                              display: "flex",
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "space-between",
                              width: "96%",
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                mt: 2,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <strong>Type:</strong>
                              {participant.ticket_type?.name}
                            </Typography>
                            <Typography sx={{ fontSize: 26, mr: 2 }}>
                              {scoreboardEntry
                                ? calculateTotalTime(
                                    scoreboardEntry.start_time,
                                    scoreboardEntry.end_time
                                  )
                                : "-"}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Pos</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Deltager</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Køn</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Alder</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scoreboardLoading || eventParticipantLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <TableRow key={index}>
                      {Array.from({ length: 7 }).map((__, cellIndex) => (
                        <TableCell key={cellIndex}>
                          <Skeleton width="80%" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                : filteredParticipants.map(({ participant, position }) => {
                    const scoreboardEntry = eventScoreboard?.find(
                      (entry: any) => entry.registration_id === participant.id
                    );

                    return (
                      <TableRow key={participant.id}>
                        <TableCell>{position}</TableCell>
                        <TableCell>
                          {participant.first_name} {participant.last_name}
                        </TableCell>
                        <TableCell>
                          {(participant.gender?.toLowerCase() ?? "") ===
                            "female" ||
                          (participant.gender?.toLowerCase() ?? "") ===
                            "kvinde" ? (
                              <Box sx={{ display: "flex", position: "relative" }}>
                                {" "}
                                <FemaleIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    fontSize: 14,
                                    position: "absolute",
                                    top: -10,
                                    left: -10,
                                  }}
                                />{" "}
                                <Typography variant="body2">Kvinde</Typography>
                              </Box>
                          ) : (participant.gender?.toLowerCase() ?? "") ===
                              "male" ||
                            (participant.gender?.toLowerCase() ?? "") ===
                              "mand" ? (
                            <Box sx={{ display: "flex", position: "relative" }}>
                              <MaleIcon
                                sx={{
                                  verticalAlign: "middle",
                                  fontSize: 14,
                                  position: "absolute",
                                  top: -10,
                                  left: -10,
                                }}
                              />
                              <Typography variant="body2">Mand</Typography>
                            </Box>
                          ) : (
                            participant.gender ?? "Andet"
                          )}
                        </TableCell>
                        <TableCell>
                          {calculateAge(participant.birthday)}
                        </TableCell>
                        <TableCell>{participant.ticket_type?.name}</TableCell>
                        <TableCell>
                          {scoreboardEntry
                            ? calculateTotalTime(
                                scoreboardEntry.start_time,
                                scoreboardEntry.end_time
                              )
                            : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default Results;
