import React, { useState } from "react";
import { Box, useMediaQuery, Snackbar, Alert } from "@mui/material";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";
import { loginUser } from "api/auth";
import { useNavigate, useLocation } from 'react-router-dom';

type Severity = "success" | "error" | "info" | "warning";

const LoginPage = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<Severity | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/profile";

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const data = await loginUser({ username, password });
      console.log('Login successful:', data);
      setSnackbarSeverity('success');
      setSnackbarMessage('Login successful! Redirecting...');
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate(from, { replace: true });
      }, 1000);
    } catch (err) {
      console.error('Login failed:', err);
      setSnackbarSeverity('error');
      setSnackbarMessage((err as Error).message);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      {isMobile ? (
        <MobilePage
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
        />
      ) : (
        <DesktopPage
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          handleLogin={handleLogin}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoginPage;
