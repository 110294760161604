import { fetchUsers } from "api/users";
import { useState, useEffect, useCallback } from "react";

export const useUsersAdmin = () => {
    const [users, setUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await fetchUsers();
            setUsers(data);
        } catch (err: any) {
            setError(err.message || "Error");
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const refetchUsers = () => {
        fetchData();
    };

    return { users, loading, error, setUsers, refetchUsers };
};