import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NavigationCardGrid from 'components/admin/NavigationCardGrid';
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';

const AdminNavigationPage: React.FC = () => {
    const linkItems = [
        { text: 'Dashboard', description: "Se statestik og grafer over aktivitet på platformen.", icon: <DashboardIcon />, path: '/admin/dashboard' },
        { text: 'Events', description: "Se events, manage dem og opret nye.", icon: <EventIcon />, path: '/admin/registrations' },
        { text: 'Users', description: "Se registrerede brugere, opret nye arrengørere m.m..", icon: <ManageAccountsIcon />, path: '/admin/users' },
        { text: 'Læsere', description: "Se registrerede brugere, opret nye arrengørere m.m..", icon: <FingerprintOutlinedIcon />, path: '/admin/readers' },
    ];

    return (
        <NavigationCardGrid
            title="Admin Navigation"
            description="Velkommen til admin panelet! Her kan du oprette events, finde brugereinformationer og meget mere..."
            linkItems={linkItems}
        />
    );
};

export default AdminNavigationPage;
