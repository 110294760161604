import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const getEvents = async (searchParams = {}) => {
  const response = await axios.get(`${API_URL}/events`, { params: searchParams });

  if (response.status !== 200) {
    throw new Error("Failed to fetch events");
  }
  return response.data;
};


export const getEvent = async (eventId: String) => {
  const response = await axios.get(`${API_URL}/events/${eventId}`);

  if (response.status !== 200) {
    throw new Error("Failed to fetch event");
  }
  return response.data;
};

export const getEventParticipants = async (eventId: String | undefined) => {
  const response = await axios.get(`${API_URL}/events/${eventId}/registrants`);

  if (response.status !== 200) {
    throw new Error("Failed to fetch event participants");
  }
  return response.data;
};

export const getEventParticipantsAdmin = async (eventId: String | undefined) => {
  const response = await axios.get(`${API_URL}/events/${eventId}/registrants/admin`, {
    withCredentials: true});

  if (response.status !== 200) {
    throw new Error("Failed to fetch event participants");
  }
  return response.data;
};

export const getEventScoreboard = async (eventId: String | undefined) => {
  const response = await axios.get(`${API_URL}/events/${eventId}/scoreboard`);

  if (response.status !== 200) {
    throw new Error("Failed to fetch event scoreboard");
  }
  return response.data;
};



export async function uploadEventImage(
  eventId: string,
  field: "banner_image" | "thumbnail_image" | "gallery_images",
  file: File
) {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.put(
      `${API_URL}/s3/${eventId}/upload-image?field=${field}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}



export const registerContestant = async (
    eventId: string,
    contestantData: {
      ticket_type_id: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      email: string;
      gender: string;
      birthday: string;
      address?: string;
      post_code?: string;
      nationality?: string;
      pi_id?: string;
      bib?: string;
    }
  ) => {
    try {
      const response = await axios.post(
        `${API_URL}/events/${eventId}/register`,
        contestantData,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      console.error("error:", error);
      throw error;
    }
  };




export const createTicketType = async (
  eventId: string,
  ticketTypeData: {
    name: string;
    description: string;
    price: string;
    available: boolean;
    stock: number | null;
  }
) => {
  try {
    const response = await axios.post(
      `${API_URL}/events/${eventId}/ticket_types`,
      ticketTypeData,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateTicketType = async (
  ticketTypeId: string,
  field: string,
  value: any
) => {
  try {
    const response = await axios.put(
      `${API_URL}/events/ticket_types/${ticketTypeId}`,
      { [field]: value },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};


export const deleteTicketType = async (ticketTypeId: string) => {
  try {
    const response = await axios.delete(`${API_URL}/events/ticket_types/${ticketTypeId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export const updateBibNumber = async (
  registrationId: string,
  bibNumber: string
) => {
  try {
    const response = await axios.put(
      `${API_URL}/events/registrations/${registrationId}/bib`,
      null,
      {
        params: { bib_number: bibNumber },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating bib number:", error);
    throw error;
  }
};