import React from "react";
import { Box, Button, TextField, Typography, Link } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "./background-animation.json";




interface MobilePageProps {
    username: string;
    setUsername: (value: string) => void;
    password: string;
    setPassword: (value: string) => void;
    handleLogin: (event: React.FormEvent<HTMLFormElement>) => void;
}

const MobilePage: React.FC<MobilePageProps> = ({
    username,
    setUsername,
    password,
    setPassword,
    handleLogin
}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <div>
            <Box sx={{ position: "fixed", zIndex: -1 }}>
                <Lottie
                    options={defaultOptions}
                    height={'100vh'}
                    width={'100vw'}
                    sx={{ zIndex: -1 }}
                />
            </Box>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                }}
            >
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        width: '90%',
                        textAlign: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleLogin}
                >
                    <Typography variant="h4" fontSize={60} gutterBottom>
                        Log ind
                    </Typography>

                    {/* <Typography variant="h4" fontSize={16} gutterBottom>
                        Brug dine sociale medier til at logge ind
                    </Typography>
                    <Box sx={{
                        m: 1,
                        minWidth: "100%",
                        display: 'flex',
                        justifyContent: 'space-evenly',
                    }}>
                        <SocialLogin isMobile={true} Icon={GoogleIcon} Social={"Google"} />
                        <SocialLogin isMobile={true} Icon={AppleIcon} Social={"Apple"} />
                        <SocialLogin isMobile={true} Icon={FacebookIcon} Social={"Facebook"} />
                    </Box>
                    <Divider sx={{ width: "100%" }}>ELLER</Divider> */}
                    <TextField
                        required
                        id="username"
                        name="username"
                        label="e-mail"
                        type="username"
                        variant="outlined"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        sx={{ backgroundColor: "white" }}
                    />
                    <TextField
                        required
                        id="password"
                        name="password"
                        label="Adgangskode"
                        type="password"
                        variant="outlined"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ backgroundColor: "white" }}
                    />
                    <Button variant="contained" color="primary" size="small" type="submit" fullWidth
                        sx={{ boxShadow: "0px 4px 2px 2px black" }}>
                        Login
                    </Button>
                    <Link href="/register" sx={{color : "black" }}>Jeg mangler en profil</Link>
                    <Link href="" sx={{color : "black" }} >Jeg har glemt min adgangskode</Link>
                </Box>
            </Box>
        </div>
    );
};

export default MobilePage;
