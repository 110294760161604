import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
} from "@mui/material";
import { parse } from "papaparse";
import { registerContestant, createTicketType } from "api/events";
import { useSnackbar } from "hooks/useSnackBar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

interface MassRegisterModalProps {
  open: boolean;
  handleClose: () => void;
  eventId: string;
  ticketTypes: { id: string; name: string }[];
}

const fieldOptions = [
  { label: "Billet-type", value: "ticket_name" },
  { label: "Fornavn", value: "first_name" },
  { label: "Efternavn", value: "last_name" },
  { label: "Telefon", value: "phone_number" },
  { label: "Email", value: "email" },
  { label: "Køn", value: "gender" },
  { label: "Fødselsdag", value: "birthday" },
  { label: "Adresse", value: "address" },
  { label: "Postnummer", value: "post_code" },
  { label: "Nationalitet", value: "nationality" },
  { label: "PI ID", value: "pi_id" },
  { label: "bib", value: "bib" },
];

const MassRegisterModal: React.FC<MassRegisterModalProps> = ({
  open,
  handleClose,
  eventId,
  ticketTypes,
}) => {
  const { showSnackbar } = useSnackbar();
  const [tableData, setTableData] = useState<string[][]>([]);
  const [columnMappings, setColumnMappings] = useState<{
    [key: number]: string;
  }>({});

  // ✅ Handle CSV File Upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (!target?.result) return;
      const csvData = parse(target.result as string, { skipEmptyLines: true })
        .data as string[][];
      setTableData(csvData);
    };
    reader.readAsText(file);
  };

  const handleMappingChange = (columnIndex: number, field: string) => {
    setColumnMappings((prev) => ({ ...prev, [columnIndex]: field }));
  };

  const createMissingTickets = async (ticketNames: string[]) => {
    const existingTickets = new Map(ticketTypes.map((t) => [t.name, t.id]));
    const newTickets = new Map();

    for (const name of ticketNames) {
      if (!existingTickets.has(name) && !newTickets.has(name)) {
        try {
          const newTicket = await createTicketType(eventId, {
            name,
            description: "Auto-created ticket type",
            price: "0",
            available: true,
            stock: null,
          });

          newTickets.set(name, newTicket.id);
        } catch {
          showSnackbar(`Fejl ved oprettelse af billet-type "${name}"!`, "error");
        }
      }
    }

    return new Map([...existingTickets, ...newTickets]); // Combine both maps
  };

  // ✅ Handle mass registration
  const handleMassRegister = async () => {
    if (!columnMappings) {
      showSnackbar("Vælg hvordan data skal mappes!", "warning");
      return;
    }

    let successCount = 0;
    let failCount = 0;

    // ✅ Extract unique ticket names from CSV
    const ticketNames = Array.from(
      new Set(
        tableData.slice(1).map((row) => {
          const ticketColumnIndex = Object.keys(columnMappings).find(
            (key) => columnMappings[parseInt(key)] === "ticket_name"
          );
          return ticketColumnIndex ? row[parseInt(ticketColumnIndex)] : null;
        })
      )
    ).filter(Boolean) as string[];

    const ticketMap = await createMissingTickets(ticketNames);

    const registrations = tableData.slice(1).map(async (row) => {
      const mappedData: any = {};

      Object.entries(columnMappings).forEach(([columnIndex, field]) => {
        mappedData[field] = row[parseInt(columnIndex)] || "";
      });

      const ticketName = mappedData.ticket_name;
      if (!ticketName || !ticketMap.has(ticketName)) {
        failCount++;
        return null;
      }

      const requiredFields = [
        "first_name",
        "last_name",
        "phone_number",
        "email",
        "gender",
        "address",
        "post_code",
        "nationality",
        "pi_id",
      ];

      requiredFields.forEach((field) => {
        if (!mappedData[field]) {
          mappedData[field] = "";
        }
      });

      if (!mappedData.birthday || mappedData.birthday.trim() === "") {
        mappedData.birthday = "1900-01-01"; 
      }

      mappedData.ticket_type_id = ticketMap.get(ticketName);
      delete mappedData.ticket_name; 

      try {
        await registerContestant(eventId, mappedData);
        successCount++;
      } catch {
        failCount++;
      }
    });

    await Promise.all(registrations);

    showSnackbar(
      `Masse-registrering færdig!  ${successCount} succesfulde,  ${failCount} fejl.`,
      "info"
    );

    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="mass-register-modal-title"
    >
      <Box sx={style}>
        <Typography variant="h5" gutterBottom>
          Upload CSV til Masse-Tilmelding
        </Typography>

        <Button variant="contained" component="label">
          Vælg CSV-fil
          <input type="file" accept=".csv" hidden onChange={handleFileUpload} />
        </Button>

        {tableData.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{ mt: 3, maxHeight: 600, overflow: "auto" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {tableData[0].map((header: any, index: number) => (
                    <TableCell key={index}>
                      <Typography variant="body2">{header}</Typography>
                      <Select
                        value={columnMappings[index] || ""}
                        onChange={(e) =>
                          handleMappingChange(index, e.target.value)
                        }
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value="">Vælg felt</MenuItem>
                        {fieldOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.slice(1).map((row: any, rowIndex: number) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell: any, cellIndex: number) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {tableData.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={handleMassRegister}
          >
            Masse-registrér
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default MassRegisterModal;
