export const ERROR_MESSAGES = {
  REGISTER_USER_ALREADY_EXISTS: "En bruger med denne email eksisterer allerede.",
  LOGIN_BAD_CREDENTIALS: "Forkert email eller password, prøv igen.",
  VERIFY_USER_BAD_TOKEN: "Ugyldig token: Venligst anmod om et nyt link for at verificere din konto.",
  DEFAULT_ERROR: "Der opstod en fejl. Prøv venligst igen senere.",
} as const;

export type ErrorKeys = keyof typeof ERROR_MESSAGES;

export function getErrorMessage(errorDetail: ErrorKeys): string {
  if (ERROR_MESSAGES[errorDetail]) {
    return ERROR_MESSAGES[errorDetail];
  }
  return ERROR_MESSAGES.DEFAULT_ERROR;
}
