import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    Typography,
    Stepper,
    Step,
    StepLabel,
} from "@mui/material";
import AddContestants from "components/eventTemplate/registration/AddContestants";
import OrderDetails from "components/eventTemplate/registration/OrderDetails";
import { Participant } from "types/interfaces";
import { countryList } from "utils/country-list";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useAuth from "hooks/useAuth";
import { useEvent } from "hooks/useEvent";
import { useParams } from "react-router-dom";

const EventRegistrationPage: React.FC = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [isFormValid, setIsFormValid] = useState(false);
    /* const [totalPrice, setTotalPrice] = useState(0);
    const [couponCode, setCouponCode] = useState(""); */
    const { profile, loading } = useAuth();
    const { eventId } = useParams<{ eventId: any }>();
    const { eventInfo } = useEvent(eventId);
    const handlePriceChange = (price: number, coupon: string) => {
        // setTotalPrice(price);
        // setCouponCode(coupon);
    };

    const handleValidationChange = (isValid: boolean) => {
        setIsFormValid(isValid);
    };


    useEffect(() => {
        if (profile) {
            console.log("Profile", profile);
            const nationality = profile.details.nationality;
            console.log("Nationality", nationality);
            const matchedCountry = nationality
                ? countryList.find((country) => country.name === nationality)
                : null;

            setParticipants([
                {
                    first_name: profile.details.first_name,
                    last_name: profile.details.last_name,
                    gender: profile.details.gender,
                    email: profile.email,
                    phone_number: profile.details.phone_number || "",
                    birthday: profile.details.birthday,
                    affiliation: profile.details.affiliation,
                    nationality: matchedCountry ? matchedCountry.name : "",
                    ticket_type: "",
                    privacyAccepted: false,
                },
            ]);
        }
    }, [profile]);


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleAddParticipant = () => {
        setParticipants([
            ...participants,
            {
                first_name: "",
                last_name: "",
                gender: "",
                email: "",
                phone_number: "",
                birthday: "",
                affiliation: "",
                nationality: "",
                ticket_type: "",
                privacyAccepted: false,
            },
        ]);
    };

    const handleRemoveParticipant = (index: number) => {
        const updatedParticipants = [...participants];
        updatedParticipants.splice(index, 1);
        setParticipants(updatedParticipants);
    };

    const handleParticipantChange = (
        index: number,
        field: string,
        value: any
    ) => {
        const updatedParticipants = [...participants];
        updatedParticipants[index] = {
            ...updatedParticipants[index],
            [field]: field === "nationality" && value ? value.name : value,
        };
        setParticipants(updatedParticipants);
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    const steps = ["Deltager information", "Ordredetaljer", "Betaling"];

    const stepperStyles = {
        padding: "20px 0",
        backgroundColor: "transparent",
    };

    const stepStyles = {
        "& .MuiStepLabel-root": {
            color: "#757575",
            display: "flex",
            flexDirection: "column",
        },
        "& .MuiStepLabel-label": {
            "&.Mui-completed": {
                color: "#fe8100",
            },
            "&.Mui-active": {
                color: "#000",
                fontWeight: "bold",
                fontSize: "1.1rem",
            },
            "&.Mui-disabled": {
                color: "#000000",
            },
        },
        "& .MuiStepIcon-root": {
            color: "#000000af",
            "&.Mui-completed": {
                color: "#fe8100",
            },
            "&.Mui-active": {
                color: "#000",
                fontWeight: "bold",
                fontSize: "1.8rem",
            },
        },
    };

    return (
        <Container
            sx={{
                mt: 12,
            }}
        >
            <Typography variant="body1" gutterBottom>
                Tilmelding til
            </Typography>
            <Typography variant="h4" gutterBottom>
                {eventInfo ? eventInfo?.name : "Error"}
            </Typography>
            <Stepper activeStep={activeStep} sx={stepperStyles}>
                {steps.map((label, index) => (
                    <Step key={index} sx={stepStyles}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box mt={2}>
                {activeStep === 0 && eventInfo && (
                    <AddContestants
                        participants={participants}
                        handleParticipantChange={handleParticipantChange}
                        handleAddParticipant={handleAddParticipant}
                        handleRemoveParticipant={handleRemoveParticipant}
                        ticket_types={eventInfo.ticket_types}
                        handleValidationChange={handleValidationChange}
                    />
                )}
                {activeStep === 1 && eventInfo && (
                    <OrderDetails
                        profile={profile}
                        participants={participants}
                        eventInfo={eventInfo}
                        onPriceChange={handlePriceChange}
                        onNext={handleNext}
                    />
                )}
                {/* {activeStep === 2 && eventInfo && profile && (
                    <PaymentStep
                        participants={participants}
                        eventInfo={eventInfo}
                        profile={profile}
                        totalPrice={totalPrice}
                        couponCode={couponCode}
                    />
                )} */}
                <Box
                    mt={2}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {activeStep > 0 && (
                        <Button fullWidth onClick={handleBack} sx={{ mr: 2 }}>
                            <ArrowBackIcon /> Tilbage
                        </Button>
                    )}
                    {activeStep < steps.length - 1 && (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleNext}
                            disabled={!isFormValid}
                        >
                            Næste <ArrowForwardIcon />
                        </Button>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default EventRegistrationPage;
