import { useState, useEffect } from "react";
import { getMyProfile } from "api/users";
import { Profile } from "types/interfaces";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isSuperuser, setIsSuperuser] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<Profile>();
  const [username , setUsername] = useState<string>("");

  useEffect(() => {
    const fetchAuthUser = async () => {
      try {
        const data: Profile = await getMyProfile();
        setIsAuthenticated(true);
        setIsSuperuser(data.is_superuser);
        setProfile(data);
        setUsername(`${data.details.first_name} ${data.details.last_name}`);
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    fetchAuthUser();
  }, []);

  return { username, profile, isAuthenticated, isSuperuser, loading };
};

export default useAuth;
