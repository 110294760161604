import React from 'react';
import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import ProfileBanner from 'components/profile/ProfileBanner';
import NavigationTabs from 'components/profile/NavigationTabs';
// import { SendVerificationEmail } from 'api/auth';
import useAuth from 'hooks/useAuth';

const ProfilePage = () => {
    // const [showAlert, setShowAlert] = useState(true);
    const { profile } = useAuth();

    // const handleVerifyEmail = () => {
    //     setShowAlert(false);
    //     SendVerificationEmail(profile!.email);
    // }


    const tabItems = [
        {
            label: "Profil",
            icon: <></>,
            component: profile ? (
                <>
                    {/* <Grid container>
                        <Grid xs={6}>
                            <LatestResults />
                        </Grid>
                        <Grid xs={6}>
                            <LatestResults />
                        </Grid>
                    </Grid> */}
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={6} md={4}>
                            <Box
                                sx={{
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    padding: "16px",
                                    textAlign: "center",
                                    backgroundColor: "#f9f9f9",
                                }}
                            >
                                <Typography variant="h6" gutterBottom>
                                    Profil er ikke tilgængelig
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Hej {profile.details.first_name}! Din profil er ikke tilgængelig endnu. Timing Buddy er stadig under udvikling og vi arbejder på at gøre din profil tilgængelig snarest muligt.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </>

            ) : (
                <Typography>Error</Typography>
            ),
        },
        /* {
            label: "Billetter",
            icon: <></>,
            component: profile ? (
                <>
                    <FutureEvents />
                </>
            ) : (
                <Typography>No profile data available</Typography>
            ),
        },
        {
            label: "Resultater",
            icon: <></>,
            component: <Typography>Resultater</Typography>,
        },
        {
            label: "Ordrehistorik",
            icon: <></>,
            component: <Typography>Ordrehistorik Content</Typography>,
        }, */
    ];


    return (
        <div>
            <Box
                component="img"
                alt="Background"
                src="/assets/profileBackground.png"
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "fixed",
                    left: 0,
                    zIndex: -1,
                    objectFit: "cover",
                    opacity: 0.35,
                    height: "100vh",
                    width: "100%",
                }}
            />



            {profile && <ProfileBanner profile={profile} />}

            <Box >
                <NavigationTabs tabs={tabItems} />
            </Box>

            {/* <Container
                disableGutters
                maxWidth={"lg"}
                sx={{
                    height: "100%",
                    padding: 5,
                    mt: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    boxSizing: "border-box",
                }}
            >
                {profile && !profile.is_verified && showAlert && (
                    <Container
                        sx={{
                            position: "fixed",
                            top: 0,
                        }}
                    >
                        <Alert
                            sx={{
                                mt: 10,
                                mb: 2,
                                width: "1080px",
                                opacity: 0.95
                            }} severity="warning">
                            <AlertTitle>Din profil er ikke verificeret</AlertTitle>
                            Du har stadig ikke verificeret din email addresse <strong>{profile.email}</strong>.
                            Alle funktioner på siden vil ikke være tilgængelige så længe din profil ikke er verificeret.
                            Tryk på <strong>Verificér</strong> for at sende en ny verificerings email.
                            <br />
                            <Box sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}>
                                <Button color="inherit" size="small" variant="outlined"
                                    sx={{
                                        fontSize: "12px",
                                        p: 1.5,
                                        mt: -2
                                    }}
                                    onClick={handleVerifyEmail}>
                                    Verificér
                                </Button>
                            </Box>
                        </Alert>
                    </Container>
                )}

            </Container> */}
        </div >
    );
};

export default ProfilePage;
