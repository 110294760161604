import React, { useEffect, useState } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import RunCircleOutlinedIcon from '@mui/icons-material/RunCircleOutlined';
import SquareIcon from '@mui/icons-material/Square';

interface EventCardProps {
    src: string;
    alt: string;
    disc: string;
    title: string;
    date: string;
    location: string;
    sport: string;
}

const resizeImage = (src: string, callback: (resizedSrc: string) => void) => {
    const img = new Image();
    img.src = src;
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
            const aspectRatio = img.width / img.height;
            const width = 800; // 360p width ( 16:9 aspect ratio)
            const height = width / aspectRatio;

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            const resizedSrc = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality

            callback(resizedSrc);
        }
    };
};

const EventCard: React.FC<EventCardProps> = ({ src, alt, disc, title, date, location, sport }) => {
    const [resizedSrc, setResizedSrc] = useState<string | null>(null);

    useEffect(() => {
        resizeImage(src, setResizedSrc);
    }, [src]);

    return (
        <Card sx={{
            flex: '1 1 24%',
            maxHeight: 550,
            height: 550,
            overflow: "hidden",
            ml: 0.5,
            mr: 0.5,
            boxShadow: 0,
            minWidth: 350,
            maxWidth: 350,
            ":hover": {
                backgroundColor: "white",
            },
            '&:hover .zoom-image': {
                transform: 'scale(1.1)',
            }
        }}>
            <CardActionArea>
                <Box sx={{
                    height: 300,
                    overflow: 'hidden',
                }}>

                    {resizedSrc && (
                        <CardMedia
                            component="img"
                            height="300"
                            image={resizedSrc}
                            alt={alt}
                            className="zoom-image"
                            sx={{
                                transition: 'transform 0.3s ease-in-out',
                                width: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    )}
                </Box>
                <CardContent sx={{ mt: 1, height: 300 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', textAlign: 'center', mb: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1 }}>
                            <Typography variant="body2" color="text.primary" fontWeight={700}>
                                {date}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mx: 1 }}>
                            <SquareIcon sx={{ fontSize: "10px" }} />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ml: 1 }}>
                            <Typography variant="body2" color="text.primary" fontWeight={700}>
                                {location}
                            </Typography>
                        </Box>


                    </Box>

                    <Typography
                        gutterBottom
                        variant="h4"
                        fontWeight={700}
                        component="div"
                        sx={{
                            overflow: 'hidden',
                        }}
                    >
                        {title}
                    </Typography>
                    <Box sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        pl: 0.4,
                        pr: 0.4,
                        pt: 0.4,
                        backgroundColor: "#000000",
                        color: "#ffffff",
                        borderStyle: "solid",
                        border: 0,
                        borderBottom: 3,
                        borderLeft: 3,
                    }}>
                        <RunCircleOutlinedIcon sx={{ fontSize: "26px" }} />
                    </Box>

                    <Divider variant='middle' sx={{
                        mt: 1,
                        mb: 1
                    }} />
                    <Typography variant="body1" color="text.secondary">
                        {disc}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default EventCard;