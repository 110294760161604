import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface LinkItem {
    text: string;
    description: string;
    icon: React.ReactElement;
    path: string;
}

interface NavigationCardGridProps {
    title: string;
    description: string;
    linkItems: LinkItem[];
}

const NavigationCardGrid: React.FC<NavigationCardGridProps> = ({ title, description, linkItems }) => {
    const navigate = useNavigate();

    return (
        <Container>
            <Typography variant="h4" gutterBottom>{title}</Typography>
            <Typography>{description}</Typography>

            <Grid container spacing={4} sx={{ mt: 2 }}>
                {linkItems.map((item, index) => (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                        <Box
                            onClick={() => navigate(item.path)}
                            sx={{
                                cursor: 'pointer',
                                boxShadow: 1,
                                '&:hover': {
                                    boxShadow: 3,
                                },
                            }}
                        >
                            <Card variant="outlined">
                                <CardContent>
                                    <Box display="flex" alignItems="center" mb={2}>
                                        {item.icon}
                                        <Typography sx={{ fontSize: 18, ml: 1 }} color="text.primary" gutterBottom>
                                            {item.text}
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2" color="text.secondary">
                                        {item.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default NavigationCardGrid;
