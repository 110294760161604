import React from 'react';
import { Typography } from '@mui/material';

const Dashboard = () => {
    return (
        <div>
            <Typography variant="h4">Admin Dashboard</Typography>
            <Typography>Welcome to the admin dashboard!</Typography>
        </div>
    );
};

export default Dashboard;
