import React from "react";
import ReactCountryFlag from "react-country-flag";
import { countryList } from "./country-list";

interface CountryFlagProps {
  nationality: string | null | undefined;
  size?: number;
}

const CountryFlag: React.FC<CountryFlagProps> = ({ nationality, size = 20 }) => {
  const country = countryList.find((c) => c.name === nationality);

  if (!country) return null;

  return (
    <ReactCountryFlag
      countryCode={country.code}
      svg
      style={{ width: size, height: size, marginRight: 8 }}
    />
  );
};

export default CountryFlag;
