import React, { useState } from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Grid as MuiGrid,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useEvent } from "hooks/useEvent";
import GeneralInfoForm from "components/admin/EventManageForms/GeneralInfoForm";
import OrganizerForm from "components/admin/EventManageForms/OrganizerForm";
import LocationForm from "components/admin/EventManageForms/LocationForm";
import ContestantRegistrationForm from "components/admin/EventManageForms/ContestantRegistrationForm";
import TicketTypeForm from "components/admin/EventManageForms/TicketTypeForm";
import ParticipantsAdmin from "components/admin/EventManageForms/ParticipantsAdmin";

const sidebarStyle = {
  height: "100%",
  overflowY: "auto",
  borderRight: "1px solid lightgray",
  padding: "16px",
};

const contentStyle = {
  flexGrow: 1,
  overflowY: "scroll",
  height: "85vh",
  padding: "16px",
};

const saveButtonStyle = {
  marginTop: "16px",
};

const EventManagePage = () => {
  const { eventId } = useParams<{ eventId: any }>();
  const [currentCategory, setCurrentCategory] = useState<string>("general");
  const { eventInfo, setEvent, refetchEvent } =
    useEvent(eventId);

  const handleCategoryChange = (category: string) => {
    setCurrentCategory(category);
    refetchEvent();
  };

  const categories = [
    { label: "General Info", value: "general", disabled: false },
    { label: "Lokation", value: "location", disabled: false },
    { label: "Arrangør Info", value: "organizer", disabled: false },
    { label: "Billetter / Heats", value: "ticket_types", disabled: false },
    {
      label: "Registrer Deltager",
      value: "register_contestant",
      disabled: false,
    },
    { label: "Deltagere", value: "participants", disabled: false },
    { label: "Skema", value: "schedule", disabled: true },
    { label: "Faciliteter", value: "amenities", disabled: true },
    { label: "Præmier", value: "awards", disabled: true },
    { label: "Merchandise", value: "merchandise", disabled: true },
  ];

  const renderCategoryContent = () => {
    if (!eventInfo) return null;

    switch (currentCategory) {
      case "general":
        return <GeneralInfoForm eventInfo={eventInfo} setEvent={setEvent} />;
      case "location":
        return (
          <LocationForm
            locationId={eventInfo.location.id}
            initialData={eventInfo.location}
          />
        );
      case "organizer":
        return (
          <OrganizerForm
            organizerId={eventInfo?.organizer.id}
            initialData={eventInfo?.organizer}
          />
        );
      case "register_contestant":
        return (
          <ContestantRegistrationForm
            eventId={eventInfo.id}
            ticketTypes={eventInfo.ticket_types}
          />
        );
      case "ticket_types":
        return (
          <TicketTypeForm
            eventId={eventInfo.id}
            ticketTypes={eventInfo.ticket_types}
          />
        );
      case "participants":
        return (
          <ParticipantsAdmin
            eventId={eventInfo.id}
          />
        );
      default:
        return null;
    }
  };

  if (!eventInfo) {
    return <Typography>Loading event details...</Typography>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <MuiGrid container spacing={2}>
        {/* Sidebar */}
        <MuiGrid item xs={3} sx={sidebarStyle}>
          <Typography variant="h4">Event Detaljer</Typography>
          <Divider sx={{ my: 2 }} />
          <List>
            {categories.map((category) => (
              <ListItemButton
                key={category.value}
                onClick={() => handleCategoryChange(category.value)}
                selected={currentCategory === category.value}
                disabled={category.disabled}
                sx={{
                  backgroundColor:
                    currentCategory === category.value ? "#fe8100" : "inherit",
                  color:
                    currentCategory === category.value ? "black" : "inherit",
                  "&:hover": { backgroundColor: "lightgray" },
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize:
                          currentCategory === category.value ? "18px" : "14px",
                        color:
                          currentCategory === category.value
                            ? "#fe8100"
                            : "inherit",
                        borderBottom:
                          currentCategory === category.value
                            ? "2px solid #fe8100"
                            : "none",
                      }}
                    >
                      {category.label}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </MuiGrid>

        {/* Main Content */}
        <MuiGrid item xs={9} sx={contentStyle}>
          {renderCategoryContent()}
          <Divider sx={{ my: 2 }} />
          <Box sx={saveButtonStyle}>
            {/* <Button variant="contained" color="primary" onClick={handleSave}>
              Gem Ændringer
            </Button> */}
          </Box>
        </MuiGrid>
      </MuiGrid>
    </Box>
  );
};

export default EventManagePage;
