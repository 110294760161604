import React, { useState } from 'react';
import { Tabs, Tab, Box, Container } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

interface NavigationTabsProps {
    tabs: { label: string; component: React.ReactNode; icon?: React.ReactElement }[];
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ tabs }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Box sx={{
                borderBottom: 1, borderColor: 'divider',
                display: "flex",
                justifyContent: "right",
                mt: -9
            }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            icon={tab.icon || undefined}
                            iconPosition="start"
                            sx={{ color: "#ffffffcf", fontSize: '0.875rem' }}
                            label={tab.label}
                            {...{ id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` }}
                        />
                    ))}
                </Tabs>
            </Box>
            {tabs.map((tab, index) => (
                <CustomTabPanel key={index} value={value} index={index}>
                    {tab.component}
                </CustomTabPanel>
            ))}
        </Container>
    );
};

export default NavigationTabs;







