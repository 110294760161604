import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import Header from 'components/layout/Header';
import Footer from 'components/layout/Footer';
import { shouldHideHeaderFooter } from './NoLayoutPaths';

const Layout = () => {
    const location = useLocation();
    const hideHeaderFooter = shouldHideHeaderFooter(location.pathname);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            {!hideHeaderFooter && <Header />}
            <Container
                disableGutters
                maxWidth={false}
                sx={{ flexGrow: 1, padding: 0, margin: 0 }}
            >
                <Outlet />
            </Container>
            {!hideHeaderFooter && <Footer />}
        </Box>
    );
};

export default Layout;
