import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "hooks/useAuth";

interface AuthGuardProps {
  children: ReactNode;
  requireSuperuser?: boolean;
}

const AuthGuard: React.FC<AuthGuardProps> = ({
  children,
  requireSuperuser = false,
}) => {
  const { isAuthenticated, isSuperuser, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requireSuperuser && !isSuperuser) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
};

export default AuthGuard;
