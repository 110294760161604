import React, { useState } from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { createEventAdmin } from "api/adminEvents";

const CreateEventPage: React.FC = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Opret nyt event
      </Typography>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            setLoading(true);
            console.log(loading);
            const data = await createEventAdmin(name);
            const eventId = data.id;
            window.location.href = `/admin/registrations/manage/event/${eventId}`;
            setLoading(false);
          } catch (error) {
            console.error("Error:", error);
            setLoading(false);
          }
        }}
      >
        <TextField
          fullWidth
          label="Event Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          required
        />
        <Box mt={3}>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Opret event
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default CreateEventPage;
