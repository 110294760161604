import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const registerReader = async (hostname: string, eventType: string, event_id: string | null) => {
    const response = await axios.post(
      `${API_URL}/sighting/register-reader`,
      { hostname, event_type: eventType, event_id },
      { withCredentials: true }
    );
    return response.data;
};

export const getAllReaders = async () => {
    const response = await axios.get(`${API_URL}/sighting/get-readers`, {
        withCredentials: true,
    });
    return response.data;
};

export const updateReader = async (hostname: string, eventType: string, event_id: string | null) => {
    const response = await axios.put(
        `${API_URL}/sighting/update-reader/${hostname}`,
        { event_type: eventType, event_id: event_id },
        { withCredentials: true }
    );
    return response.data;
};

export const deleteReader = async (id: string) => {
    const response = await axios.delete(`${API_URL}/sighting/delete-reader/${id}`, {
        withCredentials: true,
    });
    return response.data;
};