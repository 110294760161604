import * as React from "react";
import { useSnackbar as useBaseSnackbar } from "@mui/base/useSnackbar";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { css, keyframes, styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

const SnackbarContext = React.createContext<{
  showSnackbar: (message: string, severity?: "success" | "error" | "warning" | "info") => void;
}>({
  showSnackbar: () => {},
});

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const [snackbar, setSnackbar] = React.useState<{ message: string | null; severity: "success" | "error" | "warning" | "info" }>({
    message: null,
    severity: "info",
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setSnackbar({ message: null, severity: "info" }), 200);
  };

  const { getRootProps, onClickAway} = useBaseSnackbar({
    onClose: handleClose,
    autoHideDuration: 4000,
  });

  const showSnackbar = (message: string, severity: "success" | "error" | "warning" | "info" = "info") => {
    setSnackbar({ message, severity });
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {open && snackbar.message && (
        <ClickAwayListener onClickAway={onClickAway}>
          <CustomSnackbar {...getRootProps()} severity={snackbar.severity}>
            <SnackbarIcon severity={snackbar.severity} />
            {snackbar.message}
          </CustomSnackbar>
        </ClickAwayListener>
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return React.useContext(SnackbarContext);
};

// ✅ Animation for snackbar slide-in
const snackbarInRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const severityStyles = {
  success: { bg: "#4CAF50", border: "#388E3C", icon: <CheckCircleIcon /> }, 
  error: { bg: "#F44336", border: "#D32F2F", icon: <ErrorIcon /> }, 
  warning: { bg: "#FF9800", border: "#F57C00", icon: <WarningIcon /> }, 
  info: { bg: "#2196F3", border: "#0966C2", icon: <InfoIcon /> }, 
};

const CustomSnackbar = styled("div")<{ severity: "success" | "error" | "warning" | "info" }>(
  ({ severity }) => css`
    position: fixed;
    z-index: 5500;
    display: flex;
    align-items: center;
    gap: 8px;
    right: 16px;
    bottom: 16px;
    max-width: 560px;
    min-width: 300px;
    background-color: ${severityStyles[severity].bg};
    border-radius: 2px;
    border: 0px solid ${severityStyles[severity].border};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    padding: 0.75rem;
    color: white;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 600;
    animation: ${snackbarInRight} 200ms;
    transition: transform 0.2s ease-out;
  `
);

const SnackbarIcon = ({ severity }: { severity: "success" | "error" | "warning" | "info" }) => {
  return (
    <span style={{ fontSize: "24px", display: "flex", alignItems: "center" }}>
      {severityStyles[severity].icon}
    </span>
  );
};
