import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
      contrastText: "#fe8100",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#ffffff",
      paper: "#fefefe",
    },
  },
  typography: {
    h1: {
      fontFamily: "Anton, Arial, sans-serif",
    },
    h2: {
      fontFamily: "Freeman, Arial, sans-serif",
    },
    h3: {
      fontFamily: "Anton, Arial, sans-serif",
    },
    h4: {
      fontFamily: "Freeman, Arial, sans-serif",
      fontSize: "1.1rem",
    },
    h5: {
      fontFamily: "Freeman, Arial, sans-serif",
      fontSize: "0.9rem",
    },
    h6: {
      fontFamily: "Tektur, Arial, sans-serif",
    },
    body1: {
      fontFamily: "Freeman, Arial, sans-serif",
      fontSize: "0.8rem",
    },
    body2: {
      fontFamily: "Tektur, Arial, sans-serif",
      fontSize: "0.7rem",
    },
    button: {
      fontFamily: "Freeman, Arial, sans-serif",
    },
    caption: {
      fontFamily: "Tektur, Arial, sans-serif",
    },
    overline: {
      fontFamily: "Tektur, Arial, sans-serif",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0)",
          boxShadow: "none",
          width: "100%",
          margin: "0 auto",
          left: 0,
          right: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          background: "#fe8100",
          fontWeight: 700,
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 15,
          paddingBottom: 15,
        },
        contained: {
          color: "white",
          borderColor: "#fe8100",
          ":hover": {
            color: "#fe8100",
            backgroundColor: "#000000",
            borderColor: "#000000",
          },
        },
        outlined: {
          backgroundColor: "transparent",
          borderColor: "#fe8100",
          ":hover": {
            color: "#fe8100",
            backgroundColor: "#000000",
            borderColor: "#000000",
          },
        },
        text: {
          backgroundColor: "white",
          color: "black",
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: "#000000",
          transition: "color 0.5s, background-position 0.5s",
          backgroundSize: "210% 100%",
          backgroundImage: "linear-gradient(to right, #000000 50%, white 50%)",
          backgroundPosition: "100% 0",
          ":hover": {
            color: "#fe8100",
            backgroundPosition: "0 0",
            transition: "color 0.5s, background-position 0.5s",
          },
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
          border: "0px solid black",
          "&.Mui-focused": {
            color: "black",
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "black",
            border: "0px solid black",
          },
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#000000",
          color: "#ffffff",
          overflow: "hidden",
          borderRadius: 8,},
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          overflow: "hidden",
          paddingBottom: 0,
          borderRadius: 8,
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#fe8100",
          "&.Mui-checked": {
            color: "#fe8100",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          backgroundColor: "#00000000",
          paddingTop: 10,
          paddingBottom: 10,
          "&:hover": {
            backgroundColor: "#fe8100",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#000000",
          ":hover": { color: "#fe8100", transition: "0.2s" },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#000000",
        },
      },
    },
  },
});

export default theme;
