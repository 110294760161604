import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "./background-animation.json";

interface DesktopPageProps {
    firstName: string;
    setFirstName: (value: string) => void;
    lastName: string;
    setLastName: (value: string) => void;
    email: string;
    setEmail: (value: string) => void;
    password: string;
    setPassword: (value: string) => void;
    handleRegister: (event: React.FormEvent<HTMLFormElement>) => void;
}


const DesktopPage: React.FC<DesktopPageProps> = ({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    handleRegister
}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <Grid container>
            <Grid item xs={5}>
                <Box
                    sx={{
                        height: "100%",
                        textAlign: "center",
                        justifyContent: "center",
                        alignContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 5,
                    }}
                >
                    <Lottie
                        options={defaultOptions}
                        height={'100vh'}
                        width={'41.666666666666664vw'}
                        sx={{
                            position: "fixed",
                            zIndex: -1,
                            top: 0,
                            left: 0,
                        }}
                    />

                    <Box
                        sx={{
                            position: "absolute",
                            width: "40%",
                            height: "40%",
                            textAlign: "center",
                            justifyContent: "center",
                            alignContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 5,
                        }}
                    >
                        <Typography variant="h1" fontSize={"60px"}>
                            Start dit eventyr!
                        </Typography>
                        <Typography variant="h4" fontSize={"22px"}>
                            💪 Opret en profil og find din næste udfordring 💪
                        </Typography>
                        <Typography variant="h4" fontSize={"16px"}>
                            Har du allerrede en bruger?
                        </Typography>
                        <Button variant="text" size="large" href="/login">
                            Log ind
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={7}>
                <Box
                    sx={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                    }}
                >
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            width: '60%',
                            textAlign: 'center',
                        }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleRegister}
                    >
                        <Typography variant="h4" fontSize={60} gutterBottom>
                            Opret Profil
                        </Typography>

                        {/* <Typography variant="h4" fontSize={20} gutterBottom>
                            Brug dine sociale medier til at oprette én profil
                        </Typography>
                        <Box sx={{
                            m: 1,
                            minWidth: "100%",
                            display: 'flex',
                            justifyContent: 'space-evenly',
                        }}>
                            <SocialLogin isMobile={false} Icon={GoogleIcon} Social={"Google"} />
                            <SocialLogin isMobile={false} Icon={AppleIcon} Social={"Apple"} />
                            <SocialLogin isMobile={false} Icon={FacebookIcon} Social={"Facebook"} />
                        </Box>
                        <Divider sx={{ width: "100%" }}>ELLER</Divider> */}
                        {/* <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="FirstName"
                                    name="FirstName"
                                    label="Fornavn"
                                    variant="outlined"
                                    fullWidth
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="LastName"
                                    name="LastName"
                                    label="Efternavn"
                                    variant="outlined"
                                    fullWidth
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="e-mail"
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="password"
                                    name="password"
                                    label="Ny adgangskode"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" size="large" type="submit" fullWidth>
                                    Opret profil
                                </Button>
                            </Grid>
                        </Grid> */}
                        <Typography variant="body1" color="text.secondary" sx={{ marginTop: 2 }}>Opretning af profiler er midlertidigt deaktiveret...</Typography>

                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default DesktopPage;
