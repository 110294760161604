import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Button,
  TextField,
  Modal,
} from "@mui/material";
import { Participant, Profile, Event } from "types/interfaces";
import OrderDetailsForm from "./OrderDetailsForm";
import { calculateTotalPrice } from "utils/Pricing";
import CountryFlag from "utils/CountryFlag";


interface OrderDetailsProps {
  participants: Participant[];
  profile: Profile | undefined;
  eventInfo: Event | undefined;
  onPriceChange: (price: number, coupon: string) => void;
  onNext: () => void;
}

const couponModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OrderDetails: React.FC<OrderDetailsProps> = ({
  participants,
  profile,
  eventInfo,
  onPriceChange,
  onNext,
}) => {
  const [firstName, setFirstName] = useState(profile?.details.first_name || "");
  const [lastName, setLastName] = useState(profile?.details.last_name || "");
  const [email, setEmail] = useState(profile?.email || "");
  const [address, setAddress] = useState(profile?.details.address || "");
  const [city, setCity] = useState(profile?.details.city || "");
  const [postalCode, setPostalCode] = useState(
    profile?.details.postal_code || ""
  );
  const [country, setCountry] = useState(profile?.details.nationality || "");
  const [phoneNumber, setPhoneNumber] = useState(
    profile?.details.phone_number || ""
  );

  const [totalPrice, setTotalPrice] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [open, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    const { subtotal, discount, tax, total } = calculateTotalPrice(
      participants,
      eventInfo,
      couponCode
    );

    setSubtotal(subtotal);
    setDiscount(discount);
    setTax(tax);
    setTotalPrice(total);

    onPriceChange(total, couponCode);
  }, [participants, eventInfo, couponCode, onPriceChange]);

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Ordredetaljer
      </Typography>

      {profile && (
        <Card
          sx={{
            mb: 4,
            p: 2,
            backgroundColor: "#fafafa",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.6)",
          }}
        >
          <CardContent sx={{ position: "relative" }}>
            <Box>
              <Typography variant="h4" gutterBottom>
                Profil og Faktureringsinformation
              </Typography>
              <Divider sx={{ mb: 4 }} />

              <OrderDetailsForm
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                email={email}
                setEmail={setEmail}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                address={address}
                setAddress={setAddress}
                city={city}
                setCity={setCity}
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                country={country}
                setCountry={setCountry}
              />
            </Box>
          </CardContent>
        </Card>
      )}

      {/* Deltager Informationer */}
      <Card
        sx={{
          position: "relative",
          p: 2,
          mb: 4,
          backgroundColor: "#fafafa",
          pb: 10,
        }}
      >
        <CardContent>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            Deltagere
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ textAlign: "center", color: "#333" }}
          >
            Venligst kontroller at de nedenstående informationer er korrekte.
          </Typography>
          <Divider sx={{ mb: 3, backgroundColor: "#000000" }} />
          <Grid
            container
            spacing={3}
            direction={"row"}
            justifyContent={"center"}
          >
            {participants.map((participant, index) => {
              const selectedTicket = eventInfo?.ticket_types.find(
                (ticket) => ticket.type === participant.ticket_type
              );

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 3,
                      backgroundColor: "#fafafa",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
                      borderRadius: "0px",
                      minHeight: "360px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        textAlign: "center",
                        fontSize: "26px",
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      {participant.first_name} {participant.last_name}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />

                    <Box sx={{ display: "flex", mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ width: "100px", fontWeight: "bold" }}
                      >
                        Køn:
                      </Typography>
                      <Typography variant="body2" sx={{ flex: 1 }}>
                        {participant.gender === "male"
                          ? "Mand"
                          : participant.gender === "female"
                          ? "Kvinde"
                          : participant.gender === "other"
                          ? "Andet"
                          : "Ukendt"}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ width: "100px", fontWeight: "bold" }}
                      >
                        Email:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          flex: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {participant.email}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ width: "100px", fontWeight: "bold" }}
                      >
                        Tlf nr:
                      </Typography>
                      <Typography variant="body2" sx={{ flex: 1 }}>
                        {participant.phone_number}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ width: "100px", fontWeight: "bold" }}
                      >
                        Fødselsdag:
                      </Typography>
                      <Typography variant="body2" sx={{ flex: 1 }}>
                        {participant.birthday}
                      </Typography>
                    </Box>
                    {participant.affiliation && (
                      <Box sx={{ display: "flex", mb: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{ width: "100px", fontWeight: "bold" }}
                        >
                          Klub:
                        </Typography>
                        <Typography variant="body2" sx={{ flex: 1 }}>
                          {participant.affiliation}
                        </Typography>
                      </Box>
                    )}

                    <Box sx={{ display: "flex", mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ width: "100px", fontWeight: "bold" }}
                      >
                        Nationalitet:
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <CountryFlag nationality={participant.nationality} />
                        {participant.nationality}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ width: "100px", fontWeight: "bold" }}
                      >
                        Event:
                      </Typography>
                      <Typography variant="body2" sx={{ flex: 1 }}>
                        {eventInfo ? eventInfo.name : "Error"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ width: "100px", fontWeight: "bold" }}
                      >
                        Billet:
                      </Typography>
                      <Typography variant="body2" sx={{ flex: 1 }}>
                        {selectedTicket ? selectedTicket.type : "Error"}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", mb: 1 }}>
                      <Typography
                        variant="body2"
                        sx={{ width: "100px", fontWeight: "bold" }}
                      >
                        Pris:
                      </Typography>
                      <Typography variant="body2" sx={{ flex: 1 }}>
                        {selectedTicket ? selectedTicket.price : "Error"}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
      {/* order sum up */}

      <Card
        sx={{
          position: "relative",
          mt: 4,
          p: 2,
          backgroundColor: "#f9f9f9",
          borderRadius: "0px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Ordreoversigt
        </Typography>
        <Button
          onClick={handleModalOpen}
          variant="contained"
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            height: "40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.8rem",
            p: 1.2,
            fontWeight: "bold",
          }}
        >
          Tilføj Rabatkode
        </Button>
        <Divider sx={{ mb: 2 }} />
        {participants.map((participant, index) => {
          const selectedTicket = eventInfo?.ticket_types.find(
            (ticket) => ticket.type === participant.ticket_type
          );

          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <Typography variant="body2">
                {participant.first_name} {participant.last_name} -{" "}
                {selectedTicket ? selectedTicket.type : "Ukendt Billet"}
              </Typography>
              <Typography variant="body2">
                {selectedTicket ? selectedTicket.price : "Error"}
              </Typography>
            </Box>
          );
        })}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body2">Subtotal</Typography>
          <Typography variant="body2">{subtotal.toFixed(2)} DKK</Typography>
        </Box>
        {discount > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography variant="body2" color="green">
              Rabat (30%)
            </Typography>
            <Typography variant="body2" color="green">
              -{discount.toFixed(2)} DKK
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="body2">Moms udgør (20%)</Typography>
          <Typography variant="body2">{tax.toFixed(2)} DKK</Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Typography variant="h6">
            <strong>Total</strong>
          </Typography>
          <Typography variant="h6">
            <strong>{totalPrice.toFixed(2)} DKK</strong>
          </Typography>
        </Box>
      </Card>

      {/* Coupon Modal */}
      <Modal open={open} onClose={handleModalClose}>
        <Box sx={couponModalStyle}>
          <Typography variant="h4" fontSize={20} gutterBottom>
            <strong>Rabatkode:</strong>
          </Typography>
          <TextField
            fullWidth
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            placeholder="Indtast rabatkode"
            sx={{ mt: 1, mb: 2 }}
          />
          <Button variant="contained" fullWidth onClick={handleModalClose}>
            Tilføj
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrderDetails;
