import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Link,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import EventMenu from "components/eventTemplate/EventMenu";
import Results from "components/eventTemplate/Results";
import Participants from "components/eventTemplate/Participants";
import { Event } from "types/interfaces";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useEventParticipants } from "hooks/useEventParticipants";

interface EventInfoProps {
  eventInfo: Event | null;
}

const EventInfo: React.FC<EventInfoProps> = ({ eventInfo }) => {
  const [alignment, setAlignment] = useState("event");
  const { eventParticipants, eventLoading, error } = useEventParticipants(eventInfo?.id || "");
  const isMobile = useMediaQuery("(max-width:600px)");

  const markdownContent = eventInfo?.description || "Ingen beskrivelse tilgængelig.";

  const handleEventMenuChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
      {/* Main Event Card */}
      <Card
        sx={{
          margin: 2,
          overflow: "visible",
          flex: 1,
          position: "relative",
          minWidth: "300px",
        }}
      >
        <EventMenu alignment={alignment} onAlignmentChange={handleEventMenuChange} />
        <CardContent sx={{ mt: 0 }}>
          {alignment === "event" && (
            <Box sx={{ fontFamily: "Tektur" }}>
              {/* Event Name Header */}
              <Box
                sx={{
                  position: "absolute",
                  backgroundColor: "#000",
                  width: "100%",
                  top: 0,
                  left: 0,
                  padding: 1.5,
                }}
              >
                <Typography variant="h4" color="#fff">
                  {eventInfo?.name || "Ukendt event"}
                </Typography>
              </Box>

              {/* Markdown Content */}
              <ReactMarkdown>{markdownContent}</ReactMarkdown>
            </Box>
          )}

          {alignment === "participants" && (
            <Participants
              eventParticipants={eventParticipants}
              eventLoading={eventLoading}
              error={error}
            />
          )}
          {alignment === "results" && (
            <Results
              eventParticipants={eventParticipants}
              eventParticipantLoading={eventLoading}
              participantError={error}
            />
          )}
        </CardContent>
      </Card>
      {alignment === "event" && (
        <Card
          sx={{
            margin: 2,
            overflow: "visible",
            position: "relative",
            minWidth: "280px",
            maxHeight: "400px",
            order: isMobile ? 1 : 0,
          }}
        >
          <CardContent sx={{ mt: 6 }}>
            <Box
              sx={{
              position: "absolute",
              backgroundColor: "#000",
              width: "100%",
              top: 0,
              left: 0,
              padding: 1.5,
              }}
            >
              <Typography variant="h4" color="#fff">
              Arrangør
              </Typography>
            </Box>
            <Typography variant="body2" gutterBottom>
              Kontakt arrangørerne af løbet direkte:
            </Typography>
            {eventInfo?.organizer?.name && (
              <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" gutterBottom>
                Arrangør:
              </Typography>
              <Typography variant="body2">{eventInfo.organizer.name}</Typography>
              </Box>
            )}
            {eventInfo?.organizer?.website && (
              <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" gutterBottom>
                Hjemmeside:
              </Typography>
              <Link
                sx={{ display: "flex", flexDirection: "row" }}
                href={`${eventInfo.organizer.website}`}
                variant="body2"
              >
                {eventInfo.organizer.website}{" "}
                <OpenInNewIcon sx={{ fontSize: "10px", ml: 0.2 }} />
              </Link>
              </Box>
            )}
            {eventInfo?.organizer?.contact_email && (
              <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" gutterBottom>
                Email:
              </Typography>
              <Link
                href={`mailto:${eventInfo.organizer.contact_email}`}
                sx={{ ":hover": { cursor: "pointer" } }}
                variant="body2"
              >
                {eventInfo.organizer.contact_email}{" "}
                <EmailOutlinedIcon sx={{ fontSize: "10px", ml: 0.2 }} />
              </Link>
              </Box>
            )}
            {(eventInfo?.organizer?.social_media?.facebook ||
              eventInfo?.organizer?.social_media?.instagram) && (
              <Box display="flex" justifyContent="space-evenly" mt={2}>
              {eventInfo.organizer.social_media?.facebook && (
                <Link
                href={`${eventInfo.organizer.social_media.facebook}`}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                variant="body2"
                >
                <IconButton size="small" color="primary">
                  <FacebookIcon fontSize="large" />
                </IconButton>
                </Link>
              )}
              {eventInfo.organizer.social_media?.instagram && (
                <Link
                href={`${eventInfo.organizer.social_media.instagram}`}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                variant="body2"
                >
                <IconButton size="small" color="primary">
                  <InstagramIcon fontSize="large" />
                </IconButton>
                </Link>
              )}
              </Box>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default EventInfo;
