import { EventScoreboardParticipant } from "types/interfaces";
import { getEventScoreboard } from "api/events";
import { useEffect, useState, useCallback } from "react";

export const useEventScoreboard = (eventId: string | undefined) => {
    const [eventScoreboard, setEventScoreboard] = useState<EventScoreboardParticipant[] | null>(null);
    const [scoreboardLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchEvent = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getEventScoreboard(eventId);
            setEventScoreboard(data);
        } catch (err: any) {
            if (err.response?.status === 404) {
                setError("Ingen resultater er blevet registreret endnu.");
            } else {
                setError(err.message);
            }
        } finally {
            setLoading(false);
        }
    }, [eventId]);

    useEffect(() => {
        fetchEvent();
    }, [fetchEvent]);

    return { eventScoreboard, scoreboardLoading, error, refetch: fetchEvent };
};