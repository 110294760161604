import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminLayout from 'components/admin/AdminLayout';

const AdminPage: React.FC = () => {
    return (
            <AdminLayout>
                <Outlet />
            </AdminLayout>
    );
};

export default AdminPage;
