export const NoLayoutPaths: string[] = ["/login", "/register", "/admin/*"];

export const shouldHideHeaderFooter = (pathname: string): boolean => {
  return NoLayoutPaths.some((path: string) => {
    if (path.endsWith("/*")) {
      return pathname.startsWith(path.slice(0, -2));
    }
    return path === pathname;
  });
};
