import React, { useState } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Box,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { EventParticipant } from "types/interfaces";
import { calculateAge } from "utils/calculateAge";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

interface EventParticipantProps {
  eventParticipants: EventParticipant[] | null;
  eventLoading: boolean;
  error: string | null;
}

const Participants: React.FC<EventParticipantProps> = ({
  eventParticipants,
  eventLoading,
  error,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = useMediaQuery("(max-width: 600px)");
  // const { eventId } = useParams<{ eventId: string }>();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredParticipants = Array.isArray(eventParticipants)
    ? eventParticipants.filter((participant: EventParticipant) =>
        Object.values(participant).some((value) =>
          Array.isArray(value)
            ? value.some((chip) =>
                chip?.toLowerCase().includes(searchTerm.toLowerCase())
              )
            : value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      ) || []
    : [];

  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          marginTop: 20,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Typography variant="h1" fontSize={60} color="error">
          {error}
        </Typography>
        <SentimentVeryDissatisfiedIcon
          color="error"
          sx={{
            fontSize: 200,
            textAlign: "center",
            mt: 2,
            mb: 2,
            color: "error",
            ml: "auto",
            mr: "auto",
          }}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "#000",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          top: 0,
          left: 0,
          padding: 1,
          pl: 1.5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h4" color="#fff">
            Deltagere
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.8 }}>
            <PeopleOutlineOutlinedIcon sx={{ fontSize: isMobile ? 16 : 16, color: "#ffffffa0" }} />
            <Typography
              variant="h6"
              fontSize={isMobile ? 12 : 12}
              mt={0.5}
              color="#ffffffa0"
            >
              Antal: {eventParticipants?.length}
            </Typography>
          </Box>
        </Box>
      </Box>
      <TextField
        label="Søg efter deltager..."
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        disabled={eventLoading}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>Navn</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Køn</TableCell>
              <TableCell sx={{ fontWeight: 700 }}>Alder</TableCell>
              {/* <TableCell sx={{ fontWeight: 700 }}>Nationalitet</TableCell> */}
              <TableCell sx={{ fontWeight: 700 }}>Billet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventLoading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton width="80%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton width="40%" />
                    </TableCell>
                    <TableCell>
                      <Skeleton width="30%" />
                    </TableCell>
                    {/* <TableCell><Skeleton width="50%" /></TableCell> */}
                    <TableCell>
                      <Skeleton width="60%" />
                    </TableCell>
                  </TableRow>
                ))
              : filteredParticipants.map((participant: EventParticipant) => (
                  <TableRow key={participant.id}>
                    <TableCell>
                      {participant.first_name} {participant.last_name}
                    </TableCell>
                    <TableCell>
                      {(participant.gender?.toLowerCase() ?? "") === "female" ||
                      (participant.gender?.toLowerCase() ?? "") === "kvinde" ? (
                        <Box sx={{ display: "flex", position: "relative" }}>
                          {" "}
                          <FemaleIcon
                            sx={{ verticalAlign: "middle", fontSize: 14, position: "absolute", top: -10, left: -10 }}
                          />{" "}
                          <Typography variant="body2">Kvinde</Typography>
                        </Box>
                      ) : (participant.gender?.toLowerCase() ?? "") ===
                          "male" ||
                        (participant.gender?.toLowerCase() ?? "") === "mand" ? (
                        <Box sx={{ display: "flex", position: "relative" }}>
                          <MaleIcon
                            sx={{ verticalAlign: "middle", fontSize: 14, position: "absolute", top: -10, left: -10 }}
                          />
                          <Typography variant="body2">Mand</Typography>
                        </Box>
                      ) : (
                        participant.gender ?? "Andet"
                      )}
                    </TableCell>
                    <TableCell>{calculateAge(participant.birthday)}</TableCell>
                    {/* <TableCell>
                      <CountryFlag nationality={participant.nationality} />
                      {participant.nationality}
                    </TableCell> */}
                    <TableCell>{participant.ticket_type?.name}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Participants;
