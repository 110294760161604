import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, CircularProgress, Container, Divider, Typography } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "./SuccessAnimation.json";

interface PaymentIntentMetadata {
  event_name: string;
  coupon?: string;
  [key: string]: string | undefined;
}

interface OrderDetails {
  id: string;
  metadata: PaymentIntentMetadata;
}

const OrderSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [playAnimation, setPlayAnimation] = useState(true);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const fetchOrderDetails = async (paymentIntentId: string) => {
      try {
        const response = await fetch(`http://localhost:8000/api/v1/payments/payment-intent/${paymentIntentId}`);
        const data = await response.json();
        setOrderDetails(data);
        setIsValid(true);
        setPlayAnimation(true);
      } catch (error) {
        console.error("Error fetching payment intent:", error);
        navigate("/404");
      } finally {
        setLoading(false);
      }
    };

    const searchParams = new URLSearchParams(location.search);
    const paymentIntent = searchParams.get("payment_intent");
    const paymentIntentClientSecret = searchParams.get("payment_intent_client_secret");
    const redirectStatus = searchParams.get("redirect_status");

    if (paymentIntent && paymentIntentClientSecret && redirectStatus === "succeeded") {
      fetchOrderDetails(paymentIntent);
    } else {
      navigate("/404");
    }
  }, [location, navigate]);

  const calculateTotal = (metadata: PaymentIntentMetadata): string => {
    let total = 0;
    const numberOfTickets = Object.keys(metadata).filter(key => key.startsWith('ticket_')).length / 2;

    for (let i = 1; i <= numberOfTickets; i++) {
      const priceString = metadata[`ticket_${i}_price`];
      if (priceString) {
        const price = parseFloat(priceString.replace(" DKK", ""));
        total += price;
      }
    }

    if (metadata.coupon && metadata.coupon !== "None") {
      total *= 0.7; // example
    }

    return total.toFixed(2);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isValid) {
    return null;
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box sx={{ mt: 0, textAlign: "center" }}>
        <Lottie
          options={defaultOptions}
          height={"100%"}
          width={"40%"}
          isStopped={!playAnimation}
          isPaused={!playAnimation}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => { },
            },
          ]}
        />
        <Typography variant="h4" gutterBottom>
          Tak for din bestilling!
        </Typography>
        <Typography variant="body1">
          Du burde meget gerne modtage en mail med information om din bestilling.
        </Typography>

        {orderDetails && (
          <Box sx={{ mt: 4, p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#333' }}>
              Ordreoversigt
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body1" sx={{ color: '#000' }}>
                <strong>Event:</strong>
              </Typography>
              <Typography variant="body1" sx={{ color: '#000' }}>
                {orderDetails.metadata.event_name}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              {Array.from({ length: Object.keys(orderDetails.metadata).filter(key => key.startsWith('ticket_')).length / 2 }).map((_, i) => (
                <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body1" sx={{ color: '#000' }}>
                    <strong>Billet {i + 1}:</strong>
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#000' }}>
                    {orderDetails.metadata[`ticket_${i + 1}_type`]} - {orderDetails.metadata[`ticket_${i + 1}_price`]}
                  </Typography>
                </Box>
              ))}
            </Box>
            {orderDetails.metadata.coupon && orderDetails.metadata.coupon !== "None" && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'green', fontWeight: 'bold' }}>
                <Typography variant="body1">
                  <strong>Rabatkode:</strong>
                </Typography>
                <Typography variant="body1">
                  {orderDetails.metadata.coupon}
                </Typography>
              </Box>
            )}
            {/* Calculate and display the total */}
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Typography variant="h6"><strong>Total:</strong></Typography>
              <Typography variant="h6">
                <strong>
                  {calculateTotal(orderDetails.metadata)} DKK
                </strong>
              </Typography>
            </Box>
          </Box>
        )}



        <Box sx={{ mt: 10, display: "flex", gap: 2, flexDirection: "row", justifyContent: "center" }}>
          <Button variant="text" fullWidth onClick={() => navigate("/profile")}>Gå til din profil</Button>
          <Button variant="contained" fullWidth onClick={() => navigate("/events")}>Find flere events</Button>
        </Box>
      </Box>
    </Container>
  );
};

export default OrderSuccessPage;
