import axios from "axios";
const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const getEventsAdmin = async (searchParams = {}) => {
  const response = await axios.get(`${API_URL}/events/admin/all`, {
    params: searchParams,
    withCredentials: true,
  });

  if (response.status !== 200) {
    throw new Error("Failed to fetch events");
  }
  return response.data;
};

export async function updateEventAdmin(
  eventId: string,
  fieldPath: string,
  value: any
) {
  const payload: Record<string, any> = {};
  const pathParts = fieldPath.split(".");

  let currentLevel = payload;
  for (let i = 0; i < pathParts.length - 1; i++) {
    const key = pathParts[i];
    if (!currentLevel[key]) {
      currentLevel[key] = {};
    }
    currentLevel = currentLevel[key];
  }

  currentLevel[pathParts[pathParts.length - 1]] = value;

  try {
    const response = await axios.put(`${API_URL}/events/${eventId}`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Faield to update event", error);
    throw error;
  }
}

export const createEventAdmin = async (name: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/events`,
      { name },
      { withCredentials: true, validateStatus: (status) => status < 500 }
    );

    if (response.status !== 200) {
      throw new Error("Failed to create event");
    }

    return response.data;
  } catch (error) {
    console.error("Error creating event:", error);
    throw error;
  }
};

export const updateOrganizerAdmin = async (
  organizerId: string,
  field: string,
  value: string
) => {
  try {
    const response = await axios.put(
      `${API_URL}/events/organizers/${organizerId}`,
      { [field]: value },
      { withCredentials: true}
      
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateLocationAdmin = async (
  locationId: string,
  field: string,
  value: string
) => {
  try {
    const response = await axios.put(
      `${API_URL}/events/locations/${locationId}`,
      { [field]: value },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
