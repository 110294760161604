import axios from "axios";
import { loginUserInterface, registerUserInterface } from "types/interfaces";
import { getErrorMessage } from "utils/ErrorMessages";

// TODO: Implement more error handling like in loginUser

// TODO: Change this to a env variable at some point:
const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const SendVerificationEmail = async (email: string) => {
  await axios.post(
     API_URL + "/auth/request-verify-token",
    {
      email,
    },
    {
      withCredentials: true,
    }
  );
};

export const VerifyUserRequest = async (token: string | null) => {
  await axios.post( API_URL + "/auth/verify", {
    token,
  });
};

export const resetPassword = async (token: string | null, password: string) => {
  await axios.post( API_URL + "/auth/reset-password", {
    token,
    password,
  });
};

export const forgotPassword = async (email: string) => {
  await axios.post( API_URL + "/auth/forgot-password", {
    email,
  });
};

export async function loginUser({ username, password }: loginUserInterface) {
  if (!username || !password) {
    throw new Error("Alle felter skal udfyldes");
  }

  try {
    const response = await axios.post(
       API_URL + "/auth/login",
      new URLSearchParams({ username, password }),
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        withCredentials: true,
        validateStatus: (status) => status < 500,
      }
    );

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const errorDetail =
        error.response.data.detail || error.response.statusText;
      throw new Error(getErrorMessage(errorDetail));
    }
    throw error;
  }
}

export async function registerUser({
    firstName,
    lastName,
    email,
    password,
}: registerUserInterface) {
    const url =  API_URL + "/auth/register";

    const payload = {
        email,
        password,
        is_active: true,
        is_superuser: false,
        is_verified: false,
        details: {
            first_name: firstName,
            last_name: lastName,
            birthday: null,
            gender: null,
            nationality: null,
            affiliation: null,
            address: null,
            city: null,
            postal_code: null,
            phone_number: null,
            image: null,
        },
    };

    if (!email || !firstName || !lastName || !password) {
        throw new Error("Alle felter skal udfyldes");
    }

    try {
        const response = await axios.post(url, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const errorDetail =
                error.response.data.detail || error.response.statusText;
            throw new Error(getErrorMessage(errorDetail));
        }
        throw error;
    }
}

export const HandleLogout = async () => {
    try {
        const response = await axios.post(
             API_URL + "/auth/logout",
            {},
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.status === 204) {
            window.location.href = "/";
        } else {
            throw new Error("Logout failed");
        }
    } catch (error) {
        throw error;
    }
};
