import React from "react";
import { Box, Button, Grid, Link, TextField, Typography } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "./background-animation.json";

interface DesktopPageProps {
  username: string;
  setUsername: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  handleLogin: (event: React.FormEvent<HTMLFormElement>) => void;
}

const DesktopPage: React.FC<DesktopPageProps> = ({
  username,
  setUsername,
  password,
  setPassword,
  handleLogin
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid container>
      <Grid item xs={7}>
        <Box
          sx={{
            height: "100vh",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              width: '60%',
              textAlign: "center",
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleLogin}
          >
            <Typography variant="h4" fontSize={60} gutterBottom>
              Log ind
            </Typography>

            {/* <Typography variant="h4" fontSize={20} gutterBottom>
              Brug dine sociale medier som log ind
            </Typography>
            <Box sx={{
              m: 1,
              minWidth: "100%",
              display: 'flex',
              justifyContent: 'space-evenly',
            }}>
              <SocialLogin isMobile={false} Icon={GoogleIcon} Social={"Google"} />
              <SocialLogin isMobile={false} Icon={AppleIcon} Social={"Apple"} />
              <SocialLogin isMobile={false} Icon={FacebookIcon} Social={"Facebook"} />
            </Box>
            <Divider sx={{ width: "100%" }}>ELLER</Divider> */}
            <TextField
              required
              id="username"
              name="username"
              label="e-mail"
              type="username"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              required
              id="password"
              name="password"
              label="Adgangskode"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button variant="contained" fullWidth color="primary" size="large" type="submit">
              Log ind
            </Button>
            <Link sx={{ color: "secondary.main" }} href="/register">
              Jeg mangler en profil
            </Link>
            <Link sx={{ color: "secondary.main" }} href="/reset-password/request">
              Jeg har glemt min adgangskode
            </Link>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={5}>
        <Box
          sx={{
            height: "100%",
            textAlign: "center",
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 5,
          }}
        >
          <Lottie
            options={defaultOptions}
            height={'100vh'}
            width={'41.666666666666664vw'}
            sx={{
              position: "fixed",
              zIndex: -1,
              top: 0,
              left: 0,
            }}
          />

          <Box
            sx={{
              position: "absolute",
              width: "40%",
              height: "40%",
              textAlign: "center",
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 5,
            }}
          >
            <Typography variant="h1" fontSize={"60px"}>
              Velkommen tilbage!
            </Typography>
            <Typography variant="h4" fontSize={"22px"}>
              💪 Log ind og find din næste udfordring 💪
            </Typography>
            <Typography variant="h4" fontSize={"16px"}>
              Mangler du en bruger
            </Typography>
            <Button variant="text" size="large" href="/register">
              Opret dig
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DesktopPage;
