import React, { useState, useEffect } from 'react';
import {
    Typography,
    TextField,
    Button,
    Box,
    Container,
    Modal,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { registerReader, getAllReaders, updateReader, deleteReader } from 'api/readers';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const Readers = () => {
    const [hostname, setHostname] = useState('');
    const [eventType, setEventType] = useState('');
    const [eventId] = useState(null as string | null);
    const [readers, setReaders] = useState([]);
    const [selectedReader, setSelectedReader] = useState<any>(null);
    const [dialog, setDialog] = useState({ open: false, message: '', type: '' });
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const fetchReaders = async () => {
        try {
            const data = await getAllReaders();
            setReaders(data);
        } catch (error: any) {
            setDialog({ open: true, message: error.response?.data?.detail || 'Failed to fetch readers', type: 'error' });
        }
    };

    useEffect(() => {
        fetchReaders();
    }, []);

    const handleRegister = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const data = await registerReader(hostname, eventType, eventId);
            setDialog({ open: true, message: `Reader registered successfully: ${JSON.stringify(data)}`, type: 'success' });
            fetchReaders();
            setIsAddModalOpen(false);
        } catch (error: any) {
            setDialog({ open: true, message: error.response?.data?.detail || 'An error occurred', type: 'error' });
        }
    };

    const handleUpdate = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const data = await updateReader(
                selectedReader.hostname,
                selectedReader.event_type,
                selectedReader.event_id ?? null
            );
            setDialog({ open: true, message: `Reader updated successfully: ${JSON.stringify(data)}`, type: 'success' });
            fetchReaders();
            setIsUpdateModalOpen(false);
        } catch (error: any) {
            setDialog({ open: true, message: error.response?.data?.detail || 'An error occurred', type: 'error' });
        }
    };

    const openUpdateModal = (reader: any) => {
        setSelectedReader(reader);
        setIsUpdateModalOpen(true);
    };

    const handleDelete = async (id: string) => {
        if (!window.confirm('Er du sikker på at du vil slette denne læser?')) {
            return;
        }
        try {
            await deleteReader(id);
            setDialog({ open: true, message: 'Reader deleted successfully', type: 'success' });
            fetchReaders();
        } catch (error: any) {
            setDialog({ open: true, message: error.response?.data?.detail || 'Failed to delete reader', type: 'error' });
        }
    };

    const closeDialog = () => {
        setDialog({ ...dialog, open: false });
    };

    return (
        <Container sx={{ position: 'relative' }}>
            <Typography variant="h5">Manage Readers</Typography>

            <Box sx={{ position: "absolute", right: 0 }}>
                <IconButton
                    color="primary"
                    onClick={() => setIsAddModalOpen(true)}
                >
                    <AddIcon sx={{ fontSize: 32 }} />
                </IconButton>
            </Box>

            <Box my={4}>
                <Typography variant="h4">Alle registreret læsere:</Typography>
                {readers.length > 0 ? (
                    <List>
                        {readers.map((reader: any) => (
                            <Box sx={{ display: 'flex', flexDirection: 'row' }} key={reader.hostname}>
                                <ListItem
                                    component="button"
                                    onClick={() => openUpdateModal(reader)}
                                    sx={{
                                        cursor: 'pointer',
                                        border: '1px solid #ccc',
                                        my: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: isMobile ? '100%' : '50%',
                                    }}
                                >
                                    <ListItemText primary={reader.hostname} />
                                    <ListItemText primary={reader.event_id} />
                                    <ListItemText primary={reader.event_type} sx={{ textAlign: 'right' }} />
                                </ListItem>
                            </Box>
                        ))}
                    </List>
                ) : (
                    <Typography>Ingen læsere fundet.</Typography>
                )}
            </Box>

            <Modal open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6">Register a new reader:</Typography>
                    <form onSubmit={handleRegister}>
                        <TextField
                            label="Hostname"
                            value={hostname}
                            onChange={(e) => setHostname(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            select
                            label="Event Type"
                            fullWidth
                            sx={{ mb: 2 }}
                            value={eventType}
                            slotProps={{
                                select: {
                                    native: true,
                                },
                            }}
                            required
                            onChange={(e) => setEventType(e.target.value)}
                        >
                            <option value="" disabled></option>
                            <option value="start">Start</option>
                            <option value="end">End</option>
                        </TextField>
                        <Button type="submit" variant="contained" color="primary">
                            Registrer læser
                        </Button>
                    </form>
                </Box>
            </Modal>

            <Modal open={isUpdateModalOpen} onClose={() => setIsUpdateModalOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box sx={{ position: "relative" }}>
                        <IconButton
                            sx={{ position: "absolute", top: 5, right: 5 }}
                            onClick={() => selectedReader?.id && handleDelete(selectedReader.id)}
                        >
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                        <Typography variant="h6">Update Reader:</Typography>
                        <form onSubmit={handleUpdate}>
                            <TextField
                                label="Hostname"
                                value={selectedReader?.hostname || ''}
                                fullWidth
                                disabled
                                margin="normal"
                            />
                            <TextField
                                label="Event ID"
                                value={selectedReader?.event_id ?? ''}
                                onChange={(e) =>
                                    setSelectedReader({
                                        ...selectedReader,
                                        event_id: e.target.value,
                                    })
                                }
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                select
                                label="New Event Type"
                                value={selectedReader?.event_type || ''}
                                onChange={(e) =>
                                    setSelectedReader({
                                        ...selectedReader,
                                        event_type: e.target.value,
                                    })
                                }
                                fullWidth
                                margin="normal"
                                required
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="" disabled>
                                    Select Event Type
                                </option>
                                <option value="start">Start</option>
                                <option value="end">End</option>
                            </TextField>
                            <Button type="submit" variant="contained" color="secondary">
                                Update Reader
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Modal>

            {/* Dialog for messages */}
            <Dialog open={dialog.open} onClose={closeDialog}>
                <DialogTitle>{dialog.type === 'success' ? 'Success' : 'Error'}</DialogTitle>
                <DialogContent>
                    <Typography>{dialog.message}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Readers;
