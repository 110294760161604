import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import EventCard from "components/EventCard";

const Homepage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Box
        sx={{
          position: "absolute",
          height: "90vh",
          width: "100%",
          overflow: "hidden",
          top: "0",
          zIndex: -1,
        }}
      >
        <Box
          component="img"
          alt="Background"
          src="/assets/background.jpg"
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            left: 0,
            zIndex: -1,
            objectFit: "cover",
          }}
        />

        <Box
          sx={{
            position: "absolute",
            height: "90vh",
            width: "100%",
            left: 0,
            top: 0,
            zIndex: 0,
            background:
              "linear-gradient(to top, #2f1100 0%, rgba(0, 0, 0, 0) 55%)",
          }}
        />
      </Box>
      <Container
        disableGutters
        maxWidth={"xl"}
        sx={{
          height: "100%",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            height: "90vh",
            width: "80%",
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, 0%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            textAlign: "left",
            boxSizing: "border-box",
          }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={10}>
              <Typography
                variant="h1"
                fontWeight={700}
                color={"#ffffff"}
                sx={{
                  textShadow: "10px 10px 20px rgba(0, 0, 0, 1)",
                  fontSize: isMobile ? "3rem" : "5rem",
                }}
              >
                TIDSTAGNING <br />& E-TICKET
              </Typography>
              <Divider
                sx={{
                  mt: 1,
                  mb: 3,
                  borderWidth: "1.5px",
                  borderStyle: "solid",
                  borderColor: "white",
                }}
              />
              <Button
                href="/events"
                size="large"
                variant="text"
                sx={{ mr: 2, color: "#df8100", borderWidth: "0px" }}
              >
                SE ALLE EVENTS
              </Button>
              {/* <Button
                size="large"
                variant="outlined"
                sx={{ borderColor: "#ffffff" }}
              >
                OPRET DIT EGET EVENT
              </Button> */}
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Box>

        {/* <Container
          disableGutters
          maxWidth={false}
          sx={{
            height: "100%",
            padding: 0,
            display: "flex",
            flexDirection: "column",
            pl: 2,
            pr: 2,
          }}
        >
          <Typography
            variant="h1"
            fontWeight={700}
            sx={{
              textAlign: "center",
              fontSize: "60px",
              mb: 6,
              mt: 6,
            }}
          >
            Kommende Events
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              justifyContent: isMobile ? "flex-start" : "center",
              overflowX: "scroll",
            }}
          >
            <EventCard
              src="/assets/event1.jpg"
              alt="Event1"
              disc="Cool Ass event with cool ass people. I really mean it, it's fucking awsome, and very cool!"
              title="Old Run - 2024"
              date="12-08-2024"
              location="Copenhagen"
              sport="Road Race"
            />
            <EventCard
              src="/assets/event2.jpg"
              alt="Event1"
              disc="Cool Ass event with cool ass people. I really mean it, it's fucking awsome, and very cool!"
              title="Big Boy Run"
              date="12-08-2024"
              location="Copenhagen"
              sport="Road Race"
            />
            <EventCard
              src="/assets/event3.jpg"
              alt="Event1"
              disc="Cool Ass event with cool ass people. I really mean it, it's fucking awsome, and very cool!"
              title="Love run - 2025 - The sequel"
              date="12-08-2024"
              location="Copenhagen"
              sport="Road Race"
            />
            <EventCard
              src="/assets/event4.jpg"
              alt="Event1"
              disc="Cool Ass event with cool ass people. I really mean it, it's fucking awsome, and very cool!"
              title="Ultra Marathon"
              date="12-08-2024"
              location="Copenhagen"
              sport="Road Race"
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              alignContent: "center",
              mb: 4,
            }}
          >
            <Button href="/events" variant="text">SE ALLE EVENTS</Button>
          </Box>
        </Container> */}

        {/* <Box
          sx={{
            mt: 10,
            width: "100%",
            height: "650px",
            backgroundColor: "#fe8100",
            pl: 10,
            pr: 10,
            mb: 6,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              component="img"
              alt="smoke-img"
              src="/assets/MuddyBuddy.jpg"
              sx={{
                height: "720px",
                width: "700px",
                objectFit: "cover",
                transform: "translate(0%, -5%)",
              }}
            />
            <Box
              sx={{
                ml: 10,
              }}
            >
              <Typography
                variant="h4"
                fontWeight={700}
                sx={{
                  textAlign: "center",
                  mt: 4,
                  mb: 0,
                  color: "white",
                }}
              >
                Fra arrangørerne bag
              </Typography>
              <Typography
                variant="h1"
                fontWeight={700}
                sx={{
                  textAlign: "center",
                  mt: 2,
                  mb: 4,
                  color: "white",
                }}
              >
                Muddy Buddy
              </Typography>
              <Divider
                sx={{
                  mt: 2,
                  mb: 2,
                  border: 1,
                  borderStyle: "solid",
                }}
              />
              <Typography
                variant="body1"
                fontSize={20}
                textAlign={"left"}
                color={"white"}
              >
                Skabt af arrangøre til arrangøre. Teamet bag Muddy Buddy med
                henblik på at skabe en nemmere og billigere løsning til løbere
                samt arrangøre. Skal du finde dit næste event, eller holde dit
                eget, vælg Timing Buddy til dit næste event!
              </Typography>

              <Button
                sx={{
                  top: 150,
                  width: 150,
                }}
              >
                KONTAKT
              </Button>
              <Button
                sx={{
                  top: 150,
                  width: 150,
                  ml: 2,
                }}
              >
                SE EVENTS
              </Button>
            </Box>
          </Box>
        </Box> */}
        <Box
          sx={{
            mt: 8,
            mr: 4,
            ml: 4,
          }}
        >
          <Grid
            container
            rowSpacing={0}
            columnSpacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Grid item xs={6}>
              <Typography
                variant="h2"
                fontWeight={200}
                sx={{
                  textAlign: isMobile ? "left" : "right",
                  fontSize: isMobile ? "0.8rem" : "2.0rem",
                  pl: isMobile ? 0 : 16,
                  pr: isMobile ? 2 : 0,
                  pb: isMobile ? 1 : 1,
                }}
              >
                Fra arrangørerne bag
              </Typography>
              <Typography
                variant="h1"
                fontWeight={700}
                sx={{
                  textAlign: isMobile ? "left" : "right",
                  fontSize: isMobile ? "1.5rem" : "3.2rem",
                  pl: isMobile ? 0 : 16,
                  pr: isMobile ? 2 : 0,
                  pb: isMobile ? 2 : 2,
                }}
              >
                Muddy Buddy
              </Typography>
              <Typography
                variant="body1"
                fontSize={isMobile ? 14 : 20}
                sx={{
                  textAlign: isMobile ? "left" : "right",
                }}
              >
                Skabt af arrangøre til arrangøre. Teamet bag Muddy Buddy med
                henblik på at skabe en nemmere og billigere løsning til løbere
                samt arrangøre. Skal du finde dit næste event, eller holde dit
                eget, vælg Timing Buddy til dit næste event!
              </Typography>

              {/* <Button
                sx={{
                  top: 10,
                  width: "100%",
                }}
              >
                KONTAKT
              </Button> */}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                textAlign: "center",
              }}
            >
              <Box
                component="img"
                alt="smoke-img"
                src="/assets/MuddyBuddy.jpg"
                /* src="/assets/smoke-img.png" */
                sx={{
                  height: isMobile ? "300px" : "400px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Homepage;
