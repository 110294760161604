import React from "react";
import { Box, Container, CircularProgress } from "@mui/material";
import EventRow from "components/eventlist/EventRow";
import useEvents from "hooks/useEvents";

const Events = () => {
  const { events, loading, error } = useEvents();

  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          top: 0,
          left: 0,
          mt: 20,
          zIndex: -1,
        }}
      ></Box>

      <Container disableGutters maxWidth={"xl"} sx={{ height: "100%", padding: 5, mt: 8 }}>
        {/* EVENT LIST */}
        <Box sx={{ height: "100%", width: "100%", textAlign: "left" }}>
          {loading && <CircularProgress />}
          {error && <p style={{ color: "red" }}>Error: {error}</p>}
          {events.length === 0 && !loading && <p>No events found.</p>}
          {events.map((event) => (
            <EventRow
              key={event.id}
              variant="user"
              eventID={event.id}
              src={event.thumbnail_image}
              alt={event.name}
              disc={event.short_description}
              title={event.name}
              date={new Date(event.start_time ?? "").toLocaleDateString()}
              location={event.location?.city}
              sport={event.type}
            />
          ))}
        </Box>
      </Container>
    </div>
  );
};

export default Events;
