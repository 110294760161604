export const calculatePrice = (basePrice: number, discount: number = 0): number => {
    if (discount < 0 || discount > 100) {
        throw new Error("Discount must be between 0 and 100");
    }
    return basePrice - (basePrice * (discount / 100));
};

export const applyVAT = (price: number, vatRate: number = 25): number => {
    return price * (1 + vatRate / 100);
};

// TODO create a more stable and secure way to handle coupon codes and calculation of the total price
const TAX_RATE = 0.25;
const DISCOUNT_RATE = 0.10;

export const calculateTotalPrice = (participants: any[], eventInformation: any, couponCode: string): {
  subtotal: number;
  discount: number;
  tax: number;
  total: number;
} => {
  let subtotal = 0;

  participants.forEach((participant) => {
    const selectedTicket = eventInformation?.ticket_types.find(
      (ticket: any) => ticket.type === participant.ticketType
    );
    if (selectedTicket) {
      subtotal += parseFloat(selectedTicket.price.replace(" DKK", ""));
    }
  });

  const discount = couponCode ? subtotal * DISCOUNT_RATE : 0;
  const tax = (subtotal - discount) * TAX_RATE;
  const total = subtotal - discount;

  return { subtotal, discount, tax, total };
};
