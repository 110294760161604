import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import AuthGuard from "utils/AuthGuard";
import EventTemplate from "pages/event/EventTemplate";
import NotFoundPage from "pages/NotFoundPage/page";
import LoginPage from "pages/login/page";
import RegisterPage from "pages/register/page";
import EventRegistrationPage from "pages/event/registration/page";
import ProfilePage from "pages/profile/page";
import ResetPasswordPage from "pages/resetPassword/page";
import AdminPage from "pages/admin/page";
import AdminNavigationPage from "pages/admin/AdminNavigationPage";

// Import specific admin pages manually
import DashboardPage from "pages/admin/dashboard/page";
import RegistrationsPage from "pages/admin/registrations/page";
import UsersPage from "pages/admin/users/page";
import CreateEventPage from "pages/admin/registrations/new/page";
import ManageEventRegistration from "pages/admin/registrations/manage/page";
import RequestResetPassword from "pages/resetPassword/request/page";
import VerifyUser from "pages/verifyUser/page";
import EventManagePage from "pages/admin/registrations/manage/event/page";
import { SnackbarProvider } from "hooks/useSnackBar";
import Readers from "pages/admin/readers/page";

const importAll = (r: __WebpackModuleApi.RequireContext) => {
  let pages = {};
  const excludeList = ["profile"];
  r.keys().forEach((key: string) => {
    const path = key.replace("./", "").replace("/page.tsx", "");
    if (!key.includes("admin/") && !excludeList.includes(path)) {
      (pages as Record<string, React.LazyExoticComponent<React.FC>>)[path] = React.lazy(() =>
        import(`./pages/${key.replace("./", "")}`)
      );
    }
  });
  return pages;
};

// Import pages and admin pages separately
const pages = importAll((require as any).context("./pages", true, /\/page\.tsx$/));

const App: React.FC = () => {
  useEffect(() => {
    document.title = "Timing Buddy";
  }, []);

  return (
    <SnackbarProvider>
      <Router>
        <Routes>
          {/* Non-admin layout */}
          <Route path="/" element={<Layout />}>
            {Object.keys(pages).map((path) => {
              const routePath = path === "homepage" ? "" : path;
              const PageComponent = (pages as Record<string, React.LazyExoticComponent<React.FC>>)[path];
              return (
                <Route
                  key={routePath}
                  path={routePath}
                  element={
                    <React.Suspense fallback={<div>Loading...</div>}>
                      <PageComponent />
                    </React.Suspense>
                  }
                />
              );
            })}

            {/* Static public routes */}
            <Route path="/event/:eventId" element={<EventTemplate />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/reset-password/request"
              element={<RequestResetPassword />}
            />
            <Route path="/verify-email" element={<VerifyUser />} />

            {/* Protected routes */}
            <Route
              path="/profile/*"
              element={
                <AuthGuard>
                  <ProfilePage />
                </AuthGuard>
              }
            />
            <Route
              path="/event/:eventId/registration"
              element={
                <AuthGuard>
                  <EventRegistrationPage />
                </AuthGuard>
              }
            />

            {/* Catch-all */}
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          {/* Admin layout */}
          <Route
            path="/admin/*"
            element={
              <AuthGuard requireSuperuser={true}>
                <AdminPage />
              </AuthGuard>
            }
          >
            <Route path="" element={<AdminNavigationPage />} />

            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="registrations" element={<RegistrationsPage />} />
            <Route path="registrations/new" element={<CreateEventPage />} />
            <Route path="readers" element={<Readers />} />
            <Route
              path="registrations/manage"
              element={<ManageEventRegistration />}
            />
            <Route
              path="registrations/manage/event/:eventId"
              element={<EventManagePage />}
            />

            <Route path="users" element={<UsersPage />} />

            <Route path="*" element={<div>Admin Route Not Found</div>} />
          </Route>
        </Routes>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
