import React, { useState } from "react";
import { Box, TextField, Typography, Button, Grid, Switch, FormControlLabel, Card, CardContent } from "@mui/material";
import { createTicketType, updateTicketType } from "api/events";
import { useSnackbar } from "hooks/useSnackBar";



interface TicketTypeFormProps {
  eventId: any;
  ticketTypes: any;
}

const TicketTypeForm: React.FC<TicketTypeFormProps> = ({ eventId, ticketTypes }) => {
  const { showSnackbar } = useSnackbar();
  const [ticketList, setTicketList] = useState(ticketTypes);
  const [newTicket, setNewTicket] = useState({
    name: "",
    description: "",
    price: "0",
    available: true,
    stock: null,
  });

  const handleChange = (id: string | null, field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = field === "available" ? event.target.checked : event.target.value;
    if (id) {
      setTicketList((prev:any) =>
        prev.map((ticket:any) => (ticket.id === id ? { ...ticket, [field]: value } : ticket))
      );
    } else {
      setNewTicket((prev:any) => ({ ...prev, [field]: value }));
    }
  };

  const handleBlur = (ticketTypeId: string, field: string) => async () => {
    try {
      await updateTicketType(ticketTypeId, field, ticketList.find((t:any) => t.id === ticketTypeId)?.[field]);
      showSnackbar("Billet-type opdateret!", "success");
    } catch {
      showSnackbar("Fejl ved opdatering!", "error");
    }
  };

  const handleCreateTicket = async () => {
    try {
      const newCreatedTicket = await createTicketType(eventId, newTicket);
      setTicketList((prev:any) => [...prev, newCreatedTicket]);
      setNewTicket({ name: "", description: "", price: "0", available: true, stock: null });
      showSnackbar("Billet-type oprettet!", "success");
    } catch {
      showSnackbar("Fejl ved oprettelse!", "error");
    }
  };

  /* const handleDeleteTicket = async (ticketTypeId: string) => {
    try {
      const response = await deleteTicketType(ticketTypeId);
      setTicketList((prev:any) => prev.filter((t:any) => t.id !== ticketTypeId));
      showSnackbar("Billet-type slettet!", "success");
    }
    catch {
      showSnackbar("Fejl ved sletning!", "error");
    }
  } */

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Billet-typer
      </Typography>

      <Grid container spacing={2}>
        {ticketList.map((ticket:any) => (
          <Grid item xs={12} md={6} key={ticket.id}>
            {/* <IconButton onClick={() => handleDeleteTicket(ticket.id)}>X</IconButton> */}
            <Card>
              <CardContent>
                <Typography variant="h6">Rediger: {ticket.name}</Typography>

                <TextField
                  label="Billet-navn"
                  value={ticket.name}
                  onChange={handleChange(ticket.id, "name")}
                  onBlur={handleBlur(ticket.id, "name")}
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="Beskrivelse"
                  value={ticket.description}
                  onChange={handleChange(ticket.id, "description")}
                  onBlur={handleBlur(ticket.id, "description")}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={2}
                />

                <TextField
                  label="Pris (DKK)"
                  type="number"
                  value={ticket.price}
                  onChange={handleChange(ticket.id, "price")}
                  onBlur={handleBlur(ticket.id, "price")}
                  fullWidth
                  margin="normal"
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={ticket.available}
                      onChange={handleChange(ticket.id, "available")}
                      onBlur={handleBlur(ticket.id, "available")}
                    />
                  }
                  label="Tilgængelig"
                />

                <TextField
                  label="Lagerbeholdning"
                  type="number"
                  value={ticket.stock ?? ""}
                  onChange={handleChange(ticket.id, "stock")}
                  onBlur={handleBlur(ticket.id, "stock")}
                  fullWidth
                  margin="normal"
                />
              </CardContent>
            </Card>
          </Grid>
        ))}

        {/* New Ticket Form */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Opret Ny Billet-type</Typography>

              <TextField
                label="Billet-navn"
                value={newTicket.name}
                onChange={handleChange(null, "name")}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Beskrivelse"
                value={newTicket.description}
                onChange={handleChange(null, "description")}
                fullWidth
                margin="normal"
                multiline
                rows={2}
              />

              <TextField
                label="Pris (DKK)"
                value={newTicket.price}
                onChange={handleChange(null, "price")}
                fullWidth
                margin="normal"
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={newTicket.available}
                    onChange={handleChange(null, "available")}
                  />
                }
                label="Tilgængelig"
              />

              <TextField
                label="Lagerbeholdning"
                type="number"
                value={newTicket.stock ?? ""}
                onChange={handleChange(null, "stock")}
                fullWidth
                margin="normal"
              />

              <Button variant="contained" color="primary" onClick={handleCreateTicket} fullWidth>
                Opret Billet-type
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketTypeForm;
