import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Profile } from "types/interfaces";

interface ProfileBannerProps {
  profile: Profile;
}

const ProfileBanner: React.FC<ProfileBannerProps> = ({ profile }) => {
  const stringAvatar = (name: string) => ({
    sx: { bgcolor: stringToColor(name) },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1]?.[0] || ""}`,
  });

  const stringToColor = (string: string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        textAlign: "left",
        boxSizing: "border-box",
        height: "360px",
        border: "solid rgb(254 129 0) 0px",
        overflow: "hidden",
      }}
    >
      <Box
        component="img"
        src="/assets/defaultProfileBanner.webp"
        sx={{
          position: "absolute",
          width: "100%",
          objectFit: "cover",
          zIndex: -2,
          transform: "translate(0%, -5%)",
          filter: "brightness(10",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          left: 0,
          top: 0,
          zIndex: -1,
          background:
            "linear-gradient(to top, #201100 0%, rgba(0, 0, 0, 0) 60%)",
        }}
      />

      <Grid container>
        <Grid
          xs={10}
          sx={{
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            mt: 22,
          }}
        >
          <Avatar
            {...stringAvatar(profile.details.first_name + " " + profile.details.last_name)}
            sx={{
              width: "100px",
              height: "100px",
              fontSize: "40px",
              border: "solid rgb(254 129 0) 6px",
              mr: 4,
              ml: 20,
            }}
          />
          <Box sx={{ mt: 3 }}>
            <Typography fontSize={40} sx={{ color: "#ffffff" }}>
              {profile.details.first_name} {profile.details.last_name}
            </Typography>
            <Typography fontSize={16} sx={{ color: "#ffffffcf" }}>
              ID: {profile.id}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileBanner;
