import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  Autocomplete,
  Divider,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import { updateUser } from "api/users";

interface UserDetails {
  first_name: string | null;
  last_name: string | null;
  birthday: string | null;
  gender: string | null;
  nationality: string | null;
  phone_number: string | null;
  address: string | null;
  city: string | null;
  postal_code: string | null;
  affiliation: string | null;
  image: string | null;
}

interface EditFormProps {
  selectedUser: {
    id: string;
    email: string;
    is_active: boolean;
    is_superuser: boolean;
    is_verified: boolean;
    details: UserDetails;
  };
  closeModal: () => void;
  refetchUsers: () => void;
}

const EditForm: React.FC<EditFormProps> = ({ selectedUser, closeModal, refetchUsers }) => {
  const [userDetails, setUserDetails] = useState<UserDetails>(
    selectedUser.details
  );

  const [formState, setFormState] = useState({
    email: selectedUser.email,
    is_active: selectedUser.is_active,
    is_superuser: selectedUser.is_superuser,
    is_verified: selectedUser.is_verified,
    details: userDetails,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    setFormState((prevState) => {
      if (type === "checkbox") {
        return {
          ...prevState,
          [name]: checked,
        };
      } else if (name in prevState.details) {
        const updatedDetails = {
          ...prevState.details,
          [name]: value,
        };

        setUserDetails(updatedDetails);

        return {
          ...prevState,
          details: updatedDetails,
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const countryList = [
    { name: "Danmark", flag: "🇩🇰", code: "DK" },
    { name: "Sverige", flag: "🇸🇪", code: "SE" },
    { name: "Norge", flag: "🇳🇴", code: "NO" },
    { name: "Tyskland", flag: "🇩🇪", code: "DE" },
    { name: "Finland", flag: "🇫🇮", code: "FI" },
    { name: "Island", flag: "🇮🇸", code: "IS" },
  ];


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const payload = {
        ...formState,
        details: userDetails,
    };

    updateUser(selectedUser.id, payload)
      .then((data) => {
      console.log("Success:", data);
      closeModal();
      refetchUsers();
      })
      .catch((error) => {
      console.error("Error:", error);
      });
    console.log("Updated User Details:", userDetails);
  };

  return (
    <Container maxWidth="md">
        <Typography variant="h4" align="left" fontSize={24} gutterBottom>Adgang</Typography>
        <Divider sx={{mb:2}} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <label>
                  <input
                    type="checkbox"
                    name="is_active"
                    checked={formState.is_active}
                    onChange={handleChange}
                  />
                  Aktiv
                </label>
              </Grid>
              <Grid item xs={4}>
                <label>
                  <input
                    type="checkbox"
                    name="is_verified"
                    checked={formState.is_verified}
                    onChange={handleChange}
                  />
                  Verificeret
                </label>
              </Grid>
              <Grid item xs={4}>
                <label>
                  <input
                    type="checkbox"
                    name="is_superuser"
                    checked={formState.is_superuser}
                    onChange={(e) => {
                      if (
                        !window.confirm(
                          "Are you sure you want to change the superuser status?"
                        )
                      ) {
                        e.preventDefault();
                      } else {
                        handleChange(e);
                      }
                    }}
                  />
                  SuperUser
                </label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Divider sx={{mb:2}} />
          <Typography variant="h4" align="left" fontSize={24} gutterBottom>Informationer</Typography>
            <Divider sx={{mb:2}} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Fornavn"
              name="first_name"
              value={userDetails.first_name}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Efternavn"
              name="last_name"
              value={userDetails.last_name}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Addresse"
              name="address"
              value={userDetails.address || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="By"
              name="city"
              value={userDetails.city || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Post nr."
              name="postal_code"
              value={userDetails.postal_code || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={countryList}
              getOptionLabel={(option) => option.name}
              fullWidth
              renderOption={(props, option) => (
                <li {...props}>
                  <ReactCountryFlag
                    countryCode={option.code}
                    svg
                    style={{ marginRight: 8 }}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => {
                const countryCode =
                  countryList.find((c) => c.name === params.inputProps.value)
                    ?.code || "";

                return (
                  <TextField
                    {...params}
                    label="Nationalitet"
                    variant="outlined"
                    autoComplete="country"
                    required
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          {params.InputProps.startAdornment}
                          {params.inputProps.value && (
                            <ReactCountryFlag
                              countryCode={countryCode}
                              svg
                              style={{ marginRight: 8 }}
                            />
                          )}
                        </>
                      ),
                    }}
                  />
                );
              }}
              value={
                countryList.find((c) => c.name === userDetails.nationality) ||
                null
              }
              onChange={(event, newValue) => {
                handleChange({
                  target: {
                    name: "nationality",
                    value: newValue ? newValue.name : "",
                  },
                } as React.ChangeEvent<HTMLInputElement>);
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Billede (Ikke implementeret endnu)"
              name="image"
              value={userDetails.image || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label="Køn"
              name="gender"
              value={userDetails.gender || ""}
              onChange={handleChange}
              variant="outlined"
              SelectProps={{
                native: true,
              }}
            >
              <option value=""></option>
              <option value="Mand">Mand</option>
              <option value="Kvinde">Kvinde</option>
              <option value="Andet">Andet</option>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Fødselsdag"
              name="birthday"
              type="date"
              value={userDetails.birthday || ""}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Tilknytning"
              name="affiliation"
              value={userDetails.affiliation || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Telefonnummer"
              name="phone_number"
              value={userDetails.phone_number || ""}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <Button onClick={closeModal} variant="text" color="success" fullWidth>
                Annuller
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button type="submit" variant="contained" color="success" fullWidth>
              Gem
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default EditForm;
