import React, { useState } from "react";
import { Box, useMediaQuery, Snackbar, Alert } from "@mui/material";
import DesktopPage from "./DesktopPage";
import MobilePage from "./MobilePage";
import { registerUser } from "api/auth";
import { useNavigate } from 'react-router-dom';


type Severity = "success" | "error" | "info" | "warning";

const RegisterPage = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<Severity | undefined>(undefined);
  const navigate = useNavigate();

  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const { data } = await registerUser({ firstName, lastName, email, password });
      console.log('Registration successful:', data);
      setSnackbarSeverity('success');
      setSnackbarMessage('Registration successful! Redirecting...');
      setSnackbarOpen(true);

      setTimeout(() => {
        navigate("/login");
      }, 1500);
    } catch (err) {
      console.error('Registration failed:', err);
      setSnackbarSeverity('error');
      setSnackbarMessage((err as Error).message);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      {isMobile ? (
        <MobilePage
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleRegister={handleRegister}
        />
      ) : (
        <DesktopPage
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          handleRegister={handleRegister}
        />
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          variant="filled"
          onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{
            width: '100%',
            borderRadius: "0",
          }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RegisterPage;
