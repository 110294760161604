import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, CircularProgress, Container, useMediaQuery } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import EventInfo from "components/eventTemplate/EventInfo";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import RunCircleOutlinedIcon from "@mui/icons-material/RunCircleOutlined";
import { useEvent } from "hooks/useEvent";

const EventTemplate = () => {
  const { eventId } = useParams<{ eventId: any }>();
  const { eventInfo, eventLoading, error } = useEvent(eventId);
  const isMobile = useMediaQuery("(max-width:600px)");

  if (eventLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Box
        sx={{
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
          marginTop: 20,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Typography variant="h1" fontSize={60} color="error">
          {error}
        </Typography>
        <SentimentVeryDissatisfiedIcon
          color="error"
          sx={{
            fontSize: 200,
            textAlign: "center",
            mt: 2,
            mb: 2,
            color: "error",
            ml: "auto",
            mr: "auto",
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative", width: "100%", overflow: "hidden" }}>
      <Box
        component="img"
        alt="trifork"
        src={
          eventInfo?.banner_image ||
          eventInfo?.thumbnail_image ||
          "https://dummyimage.com/600x400/000/ffa514&text=+"
        }
        sx={{
          height: "70vh",
          width: "100%",
          left: 0,
          zIndex: -1,
          objectFit: "cover",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          height: "70vh",
          width: "100%",
          left: 0,
          top: 0,
          zIndex: 0,
          background:
            "linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 50%)",
        }}
      />
      <Container>
        <Container
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            mt: -30,
          }}
        >
          <Typography
            variant="h3"
            color={"white"}
            sx={{
              textShadow: "10px 10px 20px rgba(0, 0, 0, 0.7)",
              width: "80%",
              fontSize: isMobile ? "24px" : "48px",
            }}
          >
            {eventInfo?.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "40%",
              mt: 2,
            }}
          >
            <Typography
              variant="h2"
              fontSize={"16px"}
              color={"white"}
              sx={{
                textShadow: "10px 10px 20px rgba(0, 0, 0, 0.5)",
                display: "flex",
                flexDirection: "row",
                mb: 1,
              }}
            >
              <CalendarTodayIcon sx={{ mr: 1 }} />
              <Typography
                variant="h2"
                fontSize={"16px"}
                color={"white"}
                sx={{
                  textShadow: "10px 10px 20px rgba(0, 0, 0, 0.5)",
                  mt: 0.5,
                }}
              >
                {eventInfo &&
                  new Date(eventInfo.start_time).toLocaleDateString("da-DK", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
              </Typography>
            </Typography>
            {eventInfo?.location?.venue && eventInfo?.location?.address && (
              <Typography
              color={"white"}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
              >
              <LocationOnOutlinedIcon sx={{ mr: 1, fontSize: 26 }} />
              <Typography
                variant="h2"
                fontSize={"16px"}
                color={"white"}
                sx={{
                textShadow: "10px 10px 20px rgba(0, 0, 0, 0.5)",
                mt: 0.5,
                }}
              >
                {eventInfo.location.venue}, {eventInfo.location.address}
              </Typography>
              </Typography>
            )}
            {eventInfo?.type && (
            <Typography
              color={"white"}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <RunCircleOutlinedIcon sx={{ mr: 1, fontSize: 26 }} />
              <Typography
                variant="h2"
                fontSize={"16px"}
                color={"white"}
                sx={{
                  textShadow: "10px 10px 20px rgba(0, 0, 0, 0.5)",
                  mt: 0.5,
                }}
              >
                {eventInfo.type}
              </Typography>
            </Typography>
            )}
          </Box>
          {eventId && (
            <Box
              sx={{
                position: "absolute",
                backgroundColor: "black",
                display: "flex",
                justifyContent: "right",
                right: 60,
                bottom: 0,
              }}
            >
              {/* <RegisterButton eventId={eventId} /> */}
            </Box>
          )}
        </Container>
      </Container>
      <Container>
        <EventInfo eventInfo={eventInfo} />
        {/*<Sponsor />*/}
        <Box
          sx={{
            height: "60px",
          }}
        ></Box>
      </Container>
      <Box>
      </Box>
    </Box>
  );
};

export default EventTemplate;
