import { useState, useEffect } from "react";
import { getEvents } from "api/events";
import { ListAllEvents } from "types/interfaces";

const useEvents = () => {
  const [events, setEvents] = useState<ListAllEvents[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setError(null);
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const data = await getEvents({});
        setEvents(data);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  return {
    events,
    loading,
    error,
  };
};

export default useEvents;
