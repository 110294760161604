import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Container, CircularProgress, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { VerifyUserRequest } from "api/auth";
import { ErrorKeys, getErrorMessage } from "utils/ErrorMessages";

const VerifyUser: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

useEffect(() => {
    const verifyToken = async () => {
        try {
            VerifyUserRequest(token);
            setSuccess(true);
        } catch (e: any) {
            const errorDetail = e.response?.data?.detail as ErrorKeys;
            setError(getErrorMessage(errorDetail));
        } finally {
            setLoading(false);
        }
    };

    verifyToken();
}, [token]);

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box mt={20} display="flex" justifyContent="center">
          <CircularProgress size={80} color="secondary" />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box mt={14}>
        {!success ? (
          <Box sx={{ textAlign: "center" }}>
            <CheckIcon color="success" fontSize="large"/>
            <Typography variant="h4" component="h1" gutterBottom>
              Din konto er blevet verificeret!
            </Typography>
            <Typography variant="body1" gutterBottom sx={{mt: 4}}>
              Mange tak for at verificere din konto. Du kan nu bare lukke denne fanen.
            </Typography>
          </Box>
        ) : (
          <Typography color="red" variant="body1">
            {error}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default VerifyUser;
