import axios from 'axios';
import { Profile } from 'types/interfaces';

const API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const updateProfile = async (profileData: Profile['details']) => {
    const updatedProfile = {
        first_name: profileData.first_name,
        last_name: profileData.last_name,
        phone_number: profileData.phone_number,
        address: profileData.address,
        city: profileData.city,
        postal_code: profileData.postal_code,
        nationality: profileData.nationality,
    };

    const response = await axios.patch(`${API_URL}/users/me`, updatedProfile, {
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (response.status !== 200) {
        throw new Error("Failed to update profile");
    }

    return response.data;
};

export const getMyProfile = async () => {
    const response = await axios.get(`${API_URL}/users/me`, {
        withCredentials: true,
    });

    if (response.status !== 200) {
        throw new Error("Failed to fetch profile");
    }

    return response.data;
}


export const fetchUsers = async () => {
    const response = await axios.get(`${API_URL}/users/`, {
        withCredentials: true,
    });

    if (response.status !== 200) {
        throw new Error("Failed to fetch users");
    }

    return response.data;
};

export const updateUser = async (selectedUserId: string, payload: Record<string, any>) => {
    try {
        const response = await axios.patch(
            `${API_URL}/users/${selectedUserId}`,
            payload,
            {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.status !== 200) {
            throw new Error("Error updating user");
        }

        return response.data;
    } catch (error) {
        throw error;
    }
};