import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import { forgotPassword } from "api/auth";

const RequestResetPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await forgotPassword(email);
      setEmailSent(true);
    } catch (e: any) {
      setError(e.response.data.detail);
      console.log(error);
    }
  };

  return (
    <Container maxWidth="sm">
      {emailSent ? (
        <Box mt={14}>
          <Typography variant="h4" component="h1" gutterBottom>
            Email sendt afsted
          </Typography>
          <Typography variant="body1" gutterBottom>
            Hvis din email findes i vores system, vil du modtage et link til at
            nulstille din adgangskode snart.
          </Typography>
        </Box>
      ) : (
        <Box mt={14}>
          <Typography variant="h4" component="h1" gutterBottom>
            Reset adganskode
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email til bruger"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Box mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!email}
              >
                Send mail
              </Button>
            </Box>
          </form>
        </Box>
      )}
    </Container>
  );
};

export default RequestResetPassword;
