import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  IconButton,
  Autocomplete,
  Checkbox,
  Link,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { EventTicketType, Participant } from "types/interfaces";
import { countryList } from "utils/country-list";
import { Link as RouterLink } from "react-router-dom";
import CountryFlag from "utils/CountryFlag";

interface AddContestantsProps {
  participants: Participant[];
  handleParticipantChange: (index: number, field: string, value: any) => void;
  handleAddParticipant: () => void;
  handleRemoveParticipant: (index: number) => void;
  ticket_types: EventTicketType[];
  handleValidationChange: (isValid: boolean) => void;
}

const AddContestants: React.FC<AddContestantsProps> = ({
  participants,
  handleParticipantChange,
  handleAddParticipant,
  handleRemoveParticipant,
  ticket_types,
  handleValidationChange,
}) => {
  const [validationErrors, setValidationErrors] = useState<boolean[]>([]);

  const validateParticipant = (participant: Participant) => {
    const requiredFields = [
      participant.first_name,
      participant.last_name,
      participant.gender,
      participant.email,
      participant.phone_number,
      participant.birthday,
      participant.nationality,
      participant.ticket_type,
      participant.privacyAccepted,
    ];

    return requiredFields.every((field) => field && field !== "");
  };

  useEffect(() => {
    const errors = participants.map(validateParticipant);
    setValidationErrors(errors);
    const allValid = errors.every((isValid) => isValid);
    handleValidationChange(allValid);
    console.log(ticket_types);
  }, [participants, handleValidationChange, ticket_types]);

  const handleFieldChange = (index: number, field: string, value: any) => {
    handleParticipantChange(index, field, value);

    const updatedErrors = [...validationErrors];
    updatedErrors[index] = !validateParticipant(participants[index]);
    setValidationErrors(updatedErrors);
    handleValidationChange(updatedErrors.every((isValid) => isValid));
  };

  return (
    <Box>
      <Typography variant="h4" mt={2} sx={{ mb: 2 }}>
        Tilføj Deltager
      </Typography>
      {participants.map((participant, index) => (
        <Box
          key={index}
          mb={6}
          mt={4}
          sx={{
            position: "relative",
            p: 6,
            pt: 2,
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.7)",
          }}
        >
          <Typography variant="h5" mt={2} sx={{ mb: 2 }}>
            Deltager {index + 1}
          </Typography>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <TextField
                label="Fornavn"
                value={participant.first_name}
                onChange={(e) =>
                  handleFieldChange(index, "first_name", e.target.value)
                }
                fullWidth
                autoComplete="given-name"
                required
                error={!participant.first_name && validationErrors[index]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Efternavn"
                value={participant.last_name}
                onChange={(e) =>
                  handleFieldChange(index, "last_name", e.target.value)
                }
                fullWidth
                autoComplete="family-name"
                required
                error={!participant.last_name && validationErrors[index]}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>Køn</Typography>
              <ToggleButtonGroup
                value={
                  participant.gender === "Mand"
                    ? "male"
                    : participant.gender === "Kvinde"
                    ? "female"
                    : participant.gender === "Andet"
                    ? "other"
                    : null
                }
                exclusive
                onChange={(_, value: string) => {
                  handleFieldChange(index, "gender", value);
                }}
                fullWidth
              >
                <ToggleButton
                  sx={{
                    borderRadius: 0,
                    transition: "ease-in-out 0.1s",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                    border: "black 1px solid",
                    "&.Mui-selected": {
                      backgroundColor: "black",
                      color: "#fe8100",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "black",
                      color: "#fe8100",
                    },
                  }}
                  value="male"
                >
                  Mand
                </ToggleButton>
                <ToggleButton
                  sx={{
                    borderRadius: 0,
                    transition: "ease-in-out 0.1s",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                    border: "black 1px solid",
                    "&.Mui-selected": {
                      backgroundColor: "black",
                      color: "#fe8100",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "black",
                      color: "#fe8100",
                    },
                  }}
                  value="female"
                >
                  Kvinde
                </ToggleButton>
                <ToggleButton
                  sx={{
                    borderRadius: 0,
                    transition: "ease-in-out 0.1s",
                    ":hover": {
                      transform: "scale(1.02)",
                    },
                    border: "black 1px solid",
                    "&.Mui-selected": {
                      backgroundColor: "black",
                      color: "#fe8100",
                    },
                    "&.Mui-selected:hover": {
                      backgroundColor: "black",
                      color: "#fe8100",
                    },
                  }}
                  value="other"
                >
                  Andet
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                value={participant.email}
                onChange={(e) =>
                  handleFieldChange(index, "email", e.target.value)
                }
                fullWidth
                autoComplete="email"
                required
                error={!participant.email && validationErrors[index]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Tlf. nr."
                type="phone"
                value={participant.phone_number}
                onChange={(e) =>
                  handleFieldChange(index, "phone_number", e.target.value)
                }
                fullWidth
                autoComplete="tel"
                required
                error={!participant.phone_number && validationErrors[index]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Fødselsdag"
                type="date"
                value={participant.birthday}
                onChange={(e) =>
                  handleFieldChange(index, "birthday", e.target.value)
                }
                fullWidth
                InputLabelProps={{ shrink: true }}
                autoComplete="bday"
                required
                error={!participant.birthday && validationErrors[index]}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Klub (Valgfri)"
                type="club"
                value={participant.affiliation}
                onChange={(e) =>
                  handleFieldChange(index, "affiliation", e.target.value)
                }
                fullWidth
                autoComplete="club"
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
              options={countryList}
              getOptionLabel={(option) => option.name}
              fullWidth
              renderOption={(props, option) => (
                <li {...props}>
                <CountryFlag nationality={option.name} size={20} />
                {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                {...params}
                label="Nationalitet"
                variant="outlined"
                autoComplete="country"
                required
                error={!participant.nationality && validationErrors[index]}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                  <>
                    {params.InputProps.startAdornment}
                    <CountryFlag
                    nationality={String(params.inputProps.value)}
                    size={20}
                    />
                  </>
                  ),
                }}
                />
              )}
              value={
                countryList.find((c : any) => c.name === participant.nationality) ||
                null
              }
              onChange={(event, newValue) => {
                handleFieldChange(index, "nationality", newValue?.name || "");
              }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>Billet</Typography>
              <Grid container spacing={1} justifyContent={"center"}>
                {ticket_types && ticket_types.length > 0
                  ? ticket_types.map((ticket) => (
                      <Grid item xs={3} key={ticket.type}>
                        <ToggleButtonGroup
                          value={participant.ticket_type}
                          exclusive
                          onChange={(e, value) =>
                            handleFieldChange(index, "ticketType", value)
                          }
                          fullWidth
                        >
                          <ToggleButton
                            sx={{
                              borderRadius: 0,
                              transition: "ease-in-out 0.1s",
                              ":hover": {
                                transform: "scale(1.02)",
                              },
                              border: "black 1px solid",
                              "&.Mui-selected": {
                                backgroundColor: "black",
                                color: "#fe8100",
                              },
                              "&.Mui-selected:hover": {
                                backgroundColor: "black",
                                color: "#fe8100",
                              },
                            }}
                            value={ticket.type}
                          >
                            {ticket.type} ({ticket.price})
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
          </Grid>
          {index > 0 && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <IconButton onClick={() => handleRemoveParticipant(index)}>
                <RemoveCircleIcon color="error" />
              </IconButton>
            </Box>
          )}

          <FormControl
            sx={{ mt: 4 }}
            required
            error={!participant.privacyAccepted && validationErrors[index]}
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "&.Mui-checked": { color: "#fe8100" } }}
                  checked={participant.privacyAccepted}
                  onChange={(e) =>
                    handleFieldChange(
                      index,
                      "privacyAccepted",
                      e.target.checked
                    )
                  }
                  required
                />
              }
              label={
                <>
                  Jeg accepterer, at oplysningerne er korrekte, samt vilkår og
                  betingelser.{" "}
                  <Link
                    sx={{ color: "#fe8100" }}
                    component={RouterLink}
                    to="/terms"
                  >
                    privatlivspolitik
                  </Link>
                </>
              }
            />
            {!participant.privacyAccepted && validationErrors[index] && (
              <FormHelperText>
                Du skal acceptere privatlivspolitik.
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      ))}
      <Button
        startIcon={<AddCircleIcon />}
        onClick={handleAddParticipant}
        sx={{ mt: 2 }}
      >
        Tilføj Deltager
      </Button>
    </Box>
  );
};

export default AddContestants;
