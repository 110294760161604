import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { updateOrganizerAdmin } from "api/adminEvents";
import { useSnackbar } from "hooks/useSnackBar";

interface OrganizerFormProps {
  organizerId: string;
  initialData: {
    name: string;
    contact_email: string;
    phone: string;
    website: string;
  };
}

const OrganizerForm: React.FC<OrganizerFormProps> = ({ organizerId, initialData }) => {
  const [formData, setFormData] = useState(initialData) as any;
  const { showSnackbar } = useSnackbar();

  const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev: any) => ({ ...prev, [field]: event.target.value }));
  };

  const handleBlur = (field: string) => async () => {
    try {
      await updateOrganizerAdmin(organizerId, field, formData[field]);
      showSnackbar("Opdateret!", "success");
    } catch {
      showSnackbar("Fejl ved opdatering!", "error");
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Arrangør Information</Typography>

      <TextField
        label="Navn"
        value={formData.name}
        onChange={handleChange("name")}
        onBlur={handleBlur("name")}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Email"
        value={formData.contact_email}
        onChange={handleChange("contact_email")}
        onBlur={handleBlur("contact_email")}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Telefon"
        value={formData.phone}
        onChange={handleChange("phone")}
        onBlur={handleBlur("phone")}
        fullWidth
        margin="normal"
      />

      <TextField
        label="Hjemmeside"
        value={formData.website}
        onChange={handleChange("website")}
        onBlur={handleBlur("website")}
        fullWidth
        margin="normal"
      />
    </Box>
  );
};

export default OrganizerForm;
