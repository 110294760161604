import React, { useEffect, useState } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, Typography, Skeleton, useMediaQuery } from '@mui/material';
import RunCircleOutlinedIcon from '@mui/icons-material/RunCircleOutlined';
import { useNavigate } from 'react-router-dom';

interface EventCardProps {
    eventID: string | undefined;
    src: string | undefined;
    alt: string;
    disc: string | undefined;
    title: string;
    date: any;
    location: string | undefined;
    sport: string | undefined;
    variant: string | undefined;
}

const resizeImage = (src: string | undefined, callback: (resizedSrc: string) => void) => {
    const img = new Image();
    if (src) {
        img.src = src;
    }
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (ctx) {
            const aspectRatio = img.width / img.height;
            const width = 800;
            const height = width / aspectRatio;

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            const resizedSrc = canvas.toDataURL('image/jpeg', 0.8);

            callback(resizedSrc);
        }
    };
};

const EventRow: React.FC<EventCardProps> = ({ src, alt, disc, title, date, location, sport, eventID, variant}) => {
    const [resizedSrc, setResizedSrc] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (src) {
            resizeImage(src, (resizedSrc) => {
                setResizedSrc(resizedSrc);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [src]);

    return (
        <Card sx={{
            width: '100%',
            display: 'flex',
            boxShadow: 0,
            mb: 0,
            ':hover': {
                backgroundColor: 'white',
            },
            '&:hover .zoom-image': {
                transform: 'scale(1.05)',
            },
        }}>
            <CardActionArea
                sx={{ display: 'flex', width: '100%', alignItems: 'stretch' }}
                onClick={() => {
                    if (variant === "admin") {
                        navigate("/admin/registrations/manage/event/" + eventID);
                    } else {
                        navigate("/event/" + eventID);
                    }
                }}
            >
                {loading ? (
                    <Skeleton
                        variant="rectangular"
                        width={isMobile ? "30%" : "16%"}
                        height={isMobile ? 100 : 140}
                        sx={{ p: isMobile ? 0.8 : 1.2 }}
                    />
                ) : (
                    <CardMedia
                        component="img"
                        image={resizedSrc || 'https://dummyimage.com/600x400/000/ffa514&text=++++NO+IMAGE++++'}
                        alt={alt}
                        className="zoom-image"
                        sx={{
                            transition: 'transform 0.1s ease-in-out',
                            width: isMobile ? '50%' : '33%',
                            minWidth: isMobile ? '140px' : '250px',
                            maxWidth: isMobile ? '180px' : '300px',
                            maxHeight: isMobile ? '120px' : '180px',
                            objectFit: 'cover',
                            p: isMobile ? 0.8 : 1.2,
                        }}
                    />
                )}
                <CardContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '83%',
                    padding: 2,
                    height: "140px"
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        {loading ? (
                            <>
                                <Skeleton variant="text" width={100} sx={{ mr: 1 }} />
                                <Skeleton variant="circular" width={10} height={10} sx={{ mx: 1 }} />
                                <Skeleton variant="text" width={100} />
                            </>
                        ) : (
                            <>
                                <Typography variant="body2" color="text.primary" fontWeight={700} sx={{ mr: 1 }}></Typography>
                            </>
                        )}
                    </Box>
                    {loading ? (
                        <Skeleton variant="text" width="80%" height={32} />
                    ) : (
                        <Typography
                            variant="h5"
                            fontWeight={700}
                            component="div"
                            sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        >
                            {title}
                        </Typography>
                    )}
                    <Divider variant="middle" sx={{ my: 1 }} />
                    {loading ? (
                        <Skeleton variant="text" width="100%" />
                    ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap', maxHeight: '48px' }}>
                            {disc}
                        </Typography>
                    )}
                    
                    {!loading && sport && (
                        <Box sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            mr: 2,
                            mt: isMobile ? 0.5 : 2,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: 'center',
                        }}>
                            <Typography variant='body2' fontSize={isMobile ? 12 : 16}>{sport}</Typography>
                            <RunCircleOutlinedIcon sx={{ fontSize: isMobile ? 16 : 26, color: '#000', ml: 0.6 }} />
                        </Box>
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default EventRow;
