import React, { useState } from "react";
import { Box, Typography, TextField, Checkbox, Link } from "@mui/material";
import { uploadEventImage } from "api/events";
import { updateEventAdmin } from "api/adminEvents";
import { Event } from "types/interfaces";
import { useSnackbar } from "hooks/useSnackBar";
import LaunchIcon from '@mui/icons-material/Launch';

const GeneralInfoForm = ({
  eventInfo,
  setEvent,
}: {
  eventInfo: Event;
  setEvent: any;
}) => {
  const [formData, setFormData] = useState(eventInfo);
  const { showSnackbar } = useSnackbar();
  const [thumbnail] = useState(
    eventInfo.thumbnail_image ||
      "https://dummyimage.com/600x400/000/ffa514&text=++++NO+IMAGE++++"
  );
  const [banner] = useState(
    eventInfo.banner_image ||
      "https://dummyimage.com/600x400/000/ffa514&text=++++NO+IMAGE++++"
  );
 /*  const [galleryImages, setGalleryImages] = useState(
    eventInfo.gallery_images || ([] as string[])
  ); */

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "thumbnail_image" | "gallery_images" | "banner_image"
  ) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      try {
        await uploadEventImage(eventInfo.id, type, file);
        showSnackbar("Opdateret!", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        console.error("thumbnail upload:", error);
        showSnackbar(`${error}`, "error");
      }
    }
  };

  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

  const handleBlur = (field: keyof Event) => async () => {
    try {
      const updatedEvent = await updateEventAdmin(
        eventInfo.id,
        field,
        formData[field]
      );
      setEvent(updatedEvent);
      showSnackbar(`Opdateret!`, "success");
    } catch (error) {
      showSnackbar(`${error}`, "error");
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Generelle Informationer
      </Typography>
      <Link href={`/event/${eventInfo.id}`} target="_blank" rel="noopener noreferrer">
        Gå til event <LaunchIcon sx={{ fontSize: 16 }} />
      </Link>
      <Box display="flex" alignItems="center" marginY={2}>
        <Checkbox
          checked={formData.visibility || false}
          onChange={(event) =>
        setFormData((prev) => ({
          ...prev,
          visibility: event.target.checked,
        }))
          }
          onBlur={handleBlur("visibility")}
        />
        <Typography>Synlighed</Typography>
      </Box>

      <TextField
        label="Event Navn"
        value={formData.name || ""}
        onChange={handleChange("name")}
        onBlur={handleBlur("name")}
        fullWidth
        margin="normal"
      />
      <Box
        display="flex"
        flexDirection="row"
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <Box>
          <Typography variant="h6">Thumbnail</Typography>
          <Typography variant="body2"> (600x400px)</Typography>
          <Typography variant="body2"> Tryk på billedet for at ændre det.</Typography>
        </Box>
        <label htmlFor="upload-thumbnail">
          <Box
            component="img"
            src={
              thumbnail ||
              "https://dummyimage.com/600x400/000/ffa514&text=++++NO+IMAGE++++"
            }
            alt="Event Thumbnail"
            sx={{
              width: "33%",
              minWidth: "250px",
              maxWidth: "300px",
              maxHeight: "150px",
              objectFit: "cover",
              p: 0,
              borderRadius: 2,
              cursor: "pointer",
              border: "2px solid #ddd",
              "&:hover": { opacity: 0.8 },
            }}
          />
        </label>

        <input
          id="upload-thumbnail"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(event) => handleFileChange(event, "thumbnail_image")}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <Box>
          <Typography variant="h6">Event Banner</Typography>
          <Typography variant="body2"> (1920x600px)</Typography>
          <Typography variant="body2"> Tryk på billedet for at ændre det.</Typography>
        </Box>
        <label htmlFor="upload-banner">
          <Box
            component="img"
            src={
              banner ||
              "https://dummyimage.com/600x400/000/ffa514&text=++++NO+IMAGE++++"
            }
            alt="Event banner"
            sx={{
              width: "40%",
              minWidth: "300px",
              maxWidth: "300px",
              maxHeight: "150px",
              objectFit: "cover",
              p: 0,
              borderRadius: 2,
              cursor: "pointer",
              border: "2px solid #ddd",
              "&:hover": { opacity: 0.8 },
            }}
          />
        </label>

        <input
          id="upload-banner"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(event) => handleFileChange(event, "banner_image")}
        />
      </Box>

      <TextField
        label="Kort beskrivelse"
        helperText="Vises som undetitel på event listen."
        value={formData.short_description || ""}
        onChange={handleChange("short_description")}
        onBlur={handleBlur("short_description")}
        fullWidth
        margin="normal"
        multiline
        rows={2}
      />

      <TextField
        select
        label="Type"
        value={formData.type || ""}
        onChange={handleChange("type")}
        onBlur={handleBlur("type")}
        fullWidth
        margin="normal"
        SelectProps={{
          native: true,
        }}
      >
        <option value="">Vælg type</option>
        <option value="Løb">Løb</option>
        <option value="OCR">OCR</option>
        <option value="Stafet">Stafet</option>
      </TextField>

      <TextField
        label="Start Time"
        type="datetime-local"
        value={
          formData.start_time
            ? new Date(formData.start_time).toISOString().slice(0, 16)
            : ""
        }
        onChange={handleChange("start_time")}
        onBlur={handleBlur("start_time")}
        fullWidth
        margin="normal"
      />

      <TextField
        label="End Time"
        type="datetime-local"
        value={
          formData.end_time
            ? new Date(formData.end_time).toISOString().slice(0, 16)
            : ""
        }
        onChange={handleChange("end_time")}
        onBlur={handleBlur("end_time")}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Event Beskrivelse"
        helperText="Vises på event siden som samlet beskrivelse af hele dagens forløb."
        value={formData.description || ""}
        onChange={handleChange("description")}
        onBlur={handleBlur("description")}
        fullWidth
        margin="normal"
        multiline
        rows={30}
      />
    </Box>
  );
};

export default GeneralInfoForm;
