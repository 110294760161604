import React, { useState } from "react";
import { Box, Container, CircularProgress } from "@mui/material";
import EventRow from "components/eventlist/EventRow";
import { ListAllEvents } from "types/interfaces";
import useEventAdmin from "hooks/useEventsAdmin";

const ManageEventRegistration: React.FC = () => {
  const [selectedEvent, setSelectedEvent] = useState<ListAllEvents>();
  const [open, setOpen] = useState<boolean>(false);
  const { events, loading, error } = useEventAdmin();
  console.log(selectedEvent);
  console.log(open);



  return (
    <div>
      <Box
        sx={{
          position: "fixed",
          height: "100vh",
          width: "100vw",
          top: 0,
          left: 0,
          mt: 20,
          zIndex: -1,
        }}
      ></Box>

      <Container
        disableGutters
        maxWidth={"xl"}
        sx={{ height: "100%", padding: 5, mt: 4 }}
      >
        {/* EVENT LIST */}
        <Box sx={{ height: "100%", width: "100%", textAlign: "left" }}>
          {loading && <CircularProgress />}
          {error && <p style={{ color: "red" }}>Error: {error}</p>}
          {events.length === 0 && !loading && <p>No events found.</p>}
          {events.map((event) => (
            <Box
              onClick={() => {
                setSelectedEvent(event);
                setOpen(true);
              }}
            >
              <EventRow
                key={event.id}
                eventID={event.id}
                variant="admin"
                src={event.thumbnail_image}
                alt={event.name}
                disc={event.short_description}
                title={event.name}
                date={new Date(event.start_time ?? "").toLocaleDateString()}
                location={event.location?.city}
                sport={event.type}
              />
            </Box>
          ))}
        </Box>
      </Container>
    </div>
  );
};

export default ManageEventRegistration;
